import React, {useEffect, useState} from "react";
import { MDBContainer, MDBAlert } from 'mdbreact';

const Alert = (props) => {
const[alert,getAlert]= useState(true);

  useEffect(()=>{ //show alert again
    if(props.error !== undefined){
    getAlert(true)
    }else{
      return
    }
  },[props.error])

  return (
    <>
    {alert ? <>
    <MDBContainer style={{display: props.error !== undefined ? 'block' : 'none'}} >
      <MDBAlert color={props.error ? 'danger' : 'success'} dismiss onClose={()=> {props.getErr(undefined); getAlert(false);}}>
        <strong>{props.error? 'Error!' : 'Success!'}</strong>       
      </MDBAlert>
    </MDBContainer>
    </> : null }
    </>
  );
};

export default Alert;