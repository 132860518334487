import React, {useState,useEffect, useContext} from "react";
import {getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { generalSearch} from "./Search.js";
import { AppContext } from "../../AppContext.js";

export default function Language(){

  const[datas, getDatas] = useState();
  const [loaded, isLoaded] = useState(false);
  
  const tableContext = useContext(AppContext);
  const table = 'languages';

  useEffect(() =>{
    getTable(table,getDatas, isLoaded);
    },[tableContext.tableUpdated]);

  return(
    <>
    <h5 className="mt-5">Languages <MDBIcon className="light-blue-text" icon="language" /></h5>  
    <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
  <MDBTableHead>
  <tr>
          <th><input className="form-control" id="myInput6" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
          <th><input className="form-control" id="myInput7" onChange={e => generalSearch(1,e.target.id)}  placeholder="search name" /></th>
          <th><input className="form-control" id="myInput8" onChange={e => generalSearch(2,e.target.id)}  placeholder="search family" /></th>
          <th><input className="form-control" id="myInput9" onChange={e => generalSearch(3, e.target.id)}  placeholder="search region" /></th>
          <th><input className="form-control" id="myInput10" onChange={e => generalSearch(4,e.target.id)}  placeholder="search branch" /></th>
          <th><input className="form-control" id="myInput11" onChange={e => generalSearch(5,e.target.id)}  placeholder="search subbranch" /></th>
          <th><input className="form-control" id="myInput12" onChange={e => generalSearch(6,e.target.id)}  placeholder="search grp" /></th>
          <th><input className="form-control" id="myInput13" onChange={e => generalSearch(7,e.target.id)}  placeholder="search subgroup" /></th>
          <th><input className="form-control" id="myInput14" onChange={e => generalSearch(8,e.target.id)}  placeholder="search subsubgroup" /></th>
          <th><input className="form-control" id="myInput15" onChange={e => generalSearch(9,e.target.id)}  placeholder="search variety" /></th>
          <th><input className="form-control" id="myInput16" onChange={e => generalSearch(10,e.target.id)}  placeholder="search rank" /></th>
          <th><input className="form-control" id="myInput17" onChange={e => generalSearch(11,e.target.id)}  placeholder="search lang_prop_id" /></th>
        </tr>
    <tr style={{background:"#33b5e5", color:"white"}}>
      <th>Id</th>
      <th>Name</th>
      <th>Family</th>
      <th>Region</th>
      <th>Branch</th>
      <th>Subbranch</th> 
      <th>Grp</th>
      <th>Subgroup</th>
      <th>Subsubgroup</th>
      <th>Variety</th>
      <th>Rank</th>
      <th>Lang_prop_id</th>
    </tr>
  </MDBTableHead>
  <MDBTableBody>
  {loaded ? 
  datas.map(data =>
  <tr key={data.id}>
  <td>{data.id}</td>
  <td>{data.name}</td>
  <td>{data.family}</td>
  <td>{data.region}</td>
  <td>{data.branch}</td>
  <td>{data.subbranch}</td>
  <td>{data.grp}</td>
  <td>{data.subgroup}</td>
  <td>{data.subsubgroup}</td>
  <td>{data.variety}</td>
  <td>{data.rank}</td>
  <td>{data.language_prop_id}</td>
</tr>
  ) 
  : null}
  </MDBTableBody>
</MDBTable>
    </>
    
)
}