import React from "react";
import { MDBBtn } from "mdbreact";

export default function Difficulty(props){

    return(
        <div className="justify-content-center flex-column align-items-center" style={{display: props.gameStart ? "none" : "flex"}}>
            <h3 className="mb-4">Please choose a<br/> game difficulty:</h3>
            <div className="d-flex flex-column justify-content-center">
            <MDBBtn color="primary" style={{width: 150}}  onClick={e => props.getDifficulty({difficulty: 0, difChoosen: true})}>Easy</MDBBtn>
            <MDBBtn color="primary" style={{width: 150}}  onClick={e => props.getDifficulty({difficulty: 1, difChoosen: true})}>Regular</MDBBtn>
            <MDBBtn color="primary" style={{width: 150}}  onClick={e => props.getDifficulty({difficulty: 2, difChoosen: true})}>Hard</MDBBtn>
            <MDBBtn color="primary" style={{width: 150}}  onClick={e => props.getDifficulty({difficulty: 3, difChoosen: true})}>Omniglot</MDBBtn>
</div>
<div className="mt-5">
<h4><strong>Controls</strong></h4>
<hr style={{margin:"0px"}}></hr>
<div className="d-flex justify-content-center flex-column mt-2">
<p><strong>Arrow keys and 1-9</strong> - Pick answer</p>
<p><strong>Spacebar and enter</strong> - Choose answer, next level</p>
<p><strong>When you choose an answer, click on a 
    language to get more details about that language</strong></p>
</div>
</div>
        </div>

    )
}