import React, {useEffect, useState} from "react";
import Navbar from "../../Navbar.js";
import axios from 'axios';
import {api} from "../../API.js";
import EasyScores from "./EasyScores.js";
import RegularScores from "./RegularScores.js";
import HardScores from "./HardScores.js";
import OmniglotScores from "./OmniglotScores.js";
import { MDBIcon ,MDBAnimation } from 'mdbreact';
import Spinner from "../../Spinner.js";

export default function HighScores(){

const[loaded, isLoaded] = useState(false);
const[difficulty, getDifficulty] = useState("easy");

const[easy, getEasyArray] = useState();
const[regular, getRegularArray] = useState();
const[hard, getHardArray] = useState();
const[omniglot, getOmniglotArray] = useState();

const getHighscores = ( difficult, setArray) =>{ 
      axios.get(`${api}highscores?gameDifficulty=${difficult}`)
      .then(response => { 
       setArray(response.data);
       isLoaded(true);
      })
    }

    useEffect(()=>{
    getHighscores('easy',getEasyArray);
    getHighscores('regular',getRegularArray);
    getHighscores('hard',getHardArray);
    getHighscores('omniglot',getOmniglotArray);
    },[]);
  

const Board = ()=>{
  switch(difficulty) {
    case "easy":
      return <EasyScores data={easy} />
   //   break;
    case "regular":
     return  <RegularScores data={regular} />
   //   break;
      case "hard":
      return  <HardScores data={hard} />
   //     break;
        case "omniglot":
        return  <OmniglotScores data={omniglot} />
   //       break;
    default:
     return <EasyScores data={easy} />
  }
}

    return(
        <>
 {loaded ? 
 <div style={{overflow:'hidden'}}>       
        <Navbar />
        <div className="mt-5">
      <h3 className="light-blue-text" ><MDBIcon icon="trophy" /> HighScores </h3>
      </div>

      <div className="mt-3">
        <select style={{width: 200}} onChange={e=>getDifficulty(e.target.value)} className="browser-default custom-select">
          <option value="easy">easy</option>
          <option value="regular">regular</option>
          <option value="hard">hard</option>
          <option value="omniglot">omniglot</option>
        </select>
      </div> 
      <MDBAnimation type="fadeIn">
<Board />
</MDBAnimation>

        </div> : <> <Spinner /></> }
        </>
    )
}