import React, {useContext, useEffect, useState} from "react";
import { GameContext } from "./Context.js";
import { MDBIcon, MDBRow,MDBProgress } from "mdbreact";
import "./game.css";


export default function Score(){
    const game = useContext(GameContext);

    const[newPoint, getNewPoint]= useState();
    const[show,isShown]=useState(false);
    const[scoreChange,whichScoreChanged]=useState('');

    const clear = () =>{
        setTimeout(function(){ isShown(false);whichScoreChanged('') }, 1000);
    }

    useEffect(()=>{
     getNewPoint(game.points);
     if(game.stopAudio){
     isShown(true);
     if(!game.singlePlayer){
     clear();
     }
     }
    },[game.lockBoard])


useEffect(()=>{
   whichScoreChanged('1') ;
   clear();
},[game.score1])

useEffect(()=>{
   whichScoreChanged('2') ;
   clear();
 },[game.score2])

 useEffect(()=>{
   whichScoreChanged('3') ;
   clear();
 },[game.score3])

 useEffect(()=>{
   whichScoreChanged('4') ;
   clear();
 },[game.score4])


 useEffect(()=>{
if(game.lockBoard){
switch(!game.multiOrder){
  case 1:
    whichScoreChanged('1');
    break;
  case 2:
    whichScoreChanged('2');
    break;
  case 3:
    whichScoreChanged('3');
    break;
  case 4:
    whichScoreChanged('4');
    break;
}
}

 },[game.multiOrder])

let max,score;

score = 0;

// Switch for the progress bar according to the current lvl
const progressBar = (a,b,c,d,e,f,g,h,i,j)=>{
switch(game.level){
  //lvl 1
  case 1:
     max=a;
    break;
  //lvl 2
  case 2:
    max= b;
    break;
  case 3: 
    max=c;
    break;
  case 4 :
    max=d;
    break;
  case 5:
    max=e;
    break;
  case 6:
    max=f;
    break;
  case 7:
    max=g;
    break;
  case 8:
    max=h;
    break;
  case 9:
    max=i;
    break;
  case 10:
    max = j;
    break;
}
score = game.score - max;
}

//Switch for the points according to the current lvl
const difSwitch = () =>{
  switch(game.difficulty){
    //easy
    case 0:
       progressBar(0,25,45,75,115,165,225,300,400,500);
      break;
    //medium
    case 1:
      progressBar(0,30,60,100,150,225,300,400,600,800);
      break;
      //hard
    case 2: 
    progressBar(0,50,100,175,275,425,600,800,1000,1250);
      break;
      //omniglot
    case 3:
      progressBar(0,100,200,350,500,700,900,1150,1400,1700);
      break;
  }
}

difSwitch();

    return(
        <>
       {game.singlePlayer ? <div id="points" className="d-flex mb-3 mt-4 justify-content-around flex-column">
        <MDBRow className="d-flex justify-content-around w-50 w-lg-25 align-self-center" >
        <div id="progressbar" className="mt-3 mb-2">
        <MDBProgress animated value={game.level === 10 && game.score - max > game.levelPoints - max ?  max :  score} min={0} max={game.levelPoints - max} height="20px" />
        <div className="d-flex justify-content-around flex-row grey-text">
        <h5>lvl {game.level}</h5>
        <p>{game.level === 10 && game.score - max > game.levelPoints - max ? "x" : score } /{game.levelPoints - max} </p>
        </div>
        </div>
        </MDBRow>
        <MDBRow className="d-flex justify-content-around w-25 align-self-center mt-3" >
        <div>
    {show ?  
    <h5 className={game.answerCorrect ? "word animated font-weight-bold green-text" 
    : "word animated font-weight-bold red-text" }>
        <strong>{game.answerCorrect ? "+" : "-" } {newPoint}</strong></h5> : ""}
        <h4>
           <MDBIcon className="blue-text mr-1" style={{fontSize:15}} icon="star" />  {game.score}
        </h4>
        </div>
        <h4><MDBIcon className="blue-text mr-1" style={{fontSize:15}} icon="heart" /> {game.lives}</h4>
        </MDBRow>
         </div>
       :      
       <> 
       {game.multiScore !== 0 ? <h5>You are playing until {game.multiScore} points</h5> :null }
<div>

       <div className="d-flex text-left justify-content-around flex-row mb-3">
<div style={{display: game.name1 === null ? 'none': "flex"}} 
className={"player justify-content-center flex-column " + (game.lives1 === -1  ? 'dead' : '' ||
game.playerOrder === 1 ? 'your-turn' : '')}>
   <h5 className="mt-2">{game.name1}</h5>
    <h4 style={{display: game.lives1 <= -1 ? 'none' : ''}}> <MDBIcon className="blue-text" style={{fontSize:15}}  icon="heart" /> {game.lives1}</h4>
    <div>
    {(scoreChange === '1'|| game.multiOrder === 1 ) && show ?  <>
        <h5 className={game.answerCorrect ? "word animated font-weight-bold green-text" 
    : "word animated font-weight-bold red-text" }>
        <strong>{game.answerCorrect ? "+" : "-" } {newPoint}</strong></h5></> : ""}

    <h4><MDBIcon className="blue-text" style={{fontSize:15}}  icon="star" /> {game.score1}</h4>
</div>
    </div>

    <div style={{display: game.name2 === null ? 'none': "flex"}} 
className={"player justify-content-center flex-column " + (game.lives2 === -1 ? 'dead' : '' ||
game.playerOrder === 2 ? ' your-turn' : '')}>
<h5 className="mt-2">{game.name2}</h5>
<h4 style={{display: game.lives2 <= -1 ? 'none' : ''}}><MDBIcon style={{fontSize:15}} className="blue-text" icon="heart" /> {game.lives2}</h4>
<div>
    {(scoreChange === '2'|| game.multiOrder === 2 ) && show?  
    <h5 className={game.answerCorrect ? "word animated font-weight-bold green-text" 
    : "word animated font-weight-bold red-text" }>
        <strong>{game.answerCorrect ? "+" : "-" } {newPoint}</strong></h5> : ""}
    <h4><MDBIcon className="blue-text" style={{fontSize:15}}  icon="star" /> {game.score2}</h4>
</div>
</div>

<div style={{display: game.name3 === null ? 'none': "flex"}} 
className={"player justify-content-center flex-column " + (game.lives3 === -1 ? 'dead' : '' ||
game.playerOrder === 3 ? 'your-turn' : '')}>
<h5 className="mt-2">{game.name3}</h5>
<h4 style={{display: game.lives3 <= -1 ? 'none' : ''}}><MDBIcon style={{fontSize:15}} className="blue-text" icon="heart" /> {game.lives3}</h4>
<div>
{(scoreChange === '3'|| game.multiOrder === 3 ) && show ?   
    <h5 className={game.answerCorrect ? "word animated font-weight-bold green-text" 
    : "word animated font-weight-bold red-text" }>
        <strong>{game.answerCorrect ? "+" : "-" } {newPoint}</strong></h5> : ""}
    <h4><MDBIcon className="blue-text" style={{fontSize:15}}  icon="star" /> {game.score3}</h4>
</div>
</div>

<div style={{display: game.name4 === null ? 'none': "flex"}} 
className={"player justify-content-center  flex-column " + (game.lives4 === -1 ? 'dead' : '' ||
game.playerOrder === 4 ? 'your-turn' : '')}>
<h5 className="mt-2">{game.name4}</h5>
<h4 style={{display: game.lives4 <= -1 ? 'none' : ''}}><MDBIcon className="blue-text" icon="heart" style={{fontSize:15}}  /> {game.lives4}</h4>
<div>
{(scoreChange === '4'|| game.multiOrder === 4 ) && show ?  
    <h5 className={game.answerCorrect ? "word animated font-weight-bold green-text" 
    : "word animated font-weight-bold red-text" }>
        <strong>{game.answerCorrect ? "+" : "-" } {newPoint}</strong></h5> : ""}
    <h4><MDBIcon className="blue-text" style={{fontSize:15}}  icon="star" /> {game.score4}</h4>
</div>
</div>
       </div>
       </div>
       </>
       
       }
 </>
    )
}