import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {addData} from "../../../API.js";
import Alert from "./Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function ArticlesModal(props){

  const [error, getErr] = useState();
  const [data ,setData]= useState({
    title: "",
    subject: "",
    url: "",
    thumbnail_url: "",
  });
  

  const table = 'articles';
  const tableContext = useContext(AppContext);
  
  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const refreshTable = () =>{
    addData(table,data,getErr);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Add article <MDBIcon far icon="newspaper" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}> 
      <MDBInput onChange={handleChange}  name="title"  label="Title" />
      <MDBInput onChange={handleChange}  name="subject"  label="Subject" className="mt-2 mb-2" />
      <MDBInput onChange={handleChange}  name="url"  label="Url" className="mt-2 mb-2" />
      <MDBInput onChange={handleChange}  name="thumbnail_url"  label="Thumbnail_url" className="mt-2 mb-2" />
        </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}