import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon} from 'mdbreact';
import axios from 'axios';
import Alert from "./Alert.js";
import {api, token} from "../../../API.js";
import "./upload.css";
import { AppContext } from "../../../AppContext.js";

export default function AudioModal(props){

const[file, chooseFile] = useState();
const[selectedFile, selectFile] = useState();
const[source, getSource] = useState();
const[languageId, getLangId] = useState();
const [error, isErr] = useState();

const tableContext = useContext(AppContext);

const fileSelected = (e) =>{
selectFile(e.target.files[0]);
chooseFile(e.target.files[0].name);
}

const fileUpload= (e) =>{

const formData = new FormData();
formData.append('sound_file',selectedFile, selectedFile.name )
formData.append('language_id', languageId)
formData.append('source', source )

  axios.post(`${api}admin/dashboard/sounds`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization':  `bearerToken ${token}`
    }
  }).then((response) => {
      //console.log(response.data);
      isErr(false);
      props.getTableUpdate(true);
  })
  .catch((error) => {
    isErr(true);
    });
}


const uploadBtn =()=>{
  document.getElementById("upload-btn").click();
}

const refreshTable = () =>{
  fileUpload()
  tableContext.getTableUpdate(true);
  setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
}

useEffect(()=>{
  if(!props.modal){
    isErr(undefined)
  }else{
    return
  }
},[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
      Add audio sample <MDBIcon className="light-blue-text"  icon="volume-up" />
        </MDBModalHeader>
        <MDBModalBody className="form-group">


  <MDBBtn className="mt-5" onClick={e=> uploadBtn()} style={{borderRadius:25}} color="info">UPLOAD</MDBBtn>

  <input  type="file" id="upload-btn" placeholder={file} onChange={fileSelected} />


      <MDBInput label="Language_id" onChange={e => getLangId(e.target.value)}  />
      <MDBInput label="Source" onChange={e => getSource(e.target.value)} className="mt-2 mb-2" />
        </MDBModalBody>
        <Alert error={error} getError={isErr}/>  
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}