import React, { useState, useEffect } from "react";
import GameMode from "./GameMode.js";
import Navbar from "../../Navbar.js";
import SinglePlayer from "./SinglePlayer.js";
import MultiPlayer from "./MultiPlayer.js";
import {GameContext} from "./Context.js";
import "./game.css";
import {token, api, getCookie } from "../../API.js";
import axios from 'axios';
import { useHistory } from "react-router-dom";

export default function Game(){
const [gameMode, getGameMode] =  useState(false); 
const [{difficulty, difChoosen}, getDifficulty] = useState({ difChoosen: false}); 
const [options, getOptions] = useState(); 
const [nextLevel, getNextLevel] = useState(false); 
const [correct, getCorrect] = useState(); 
const [score, getScore] = useState(0); 
const [{level, points}, getPoints] = useState({}); 
const [levelPoints, getLevelPoints] = useState();
const [audio, getAudio]= useState(); 
const [lockBoard, isLocked]=useState(false); 
const [lives, getLives] = useState(3); 
const [details, getDetails] = useState(); 
const [singlePlayer, getSinglePlayer] = useState(); 
const[numberOfPlayers, getNumberOfPlayers] = useState(2);
const[{name1, score1, lives1}, getPlayer1]= useState({name1:null, score1: null, lives1: null});
const[{name2, score2, lives2}, getPlayer2]= useState({name2:null, score2: null, lives2: null}); 
const[{name3, score3, lives3}, getPlayer3]= useState({name3:null, score3: null, lives3: null});
const[{name4, score4, lives4}, getPlayer4]= useState({name4:null, score4: null, lives4: null});
const[playerOrder, getOrder] = useState(1);
const[arrayOfPlayers, getArray] = useState([]);
const[multiScore, setMultiScore] = useState(0);
const[audioSource, getSource]= useState();
const[volume,setVolume] = useState(0.5);
const[autoPlay, setAuto] = useState();
const[stopAudio, isAudioStopped] = useState(false);
const[gameType, getGameType] = useState('');
const[deadPlayer, getDeadPlayer]=useState('');
const[gameOver, isGameOver] = useState(false);
const[answerCorrect,isAnswerCorrect]=useState();
const[multiOrder,getMultiOrder]=useState();
const[iso, isIso] = useState();
const[isoModal, getIsoModal]=useState();


const roundOrder = (players) =>{
    var array = []
    for (var i = 1; i <= players; i++) {
        array.push(i);
        getArray(array); 
    }
    } 

useEffect(()=>{
    roundOrder(numberOfPlayers);
},[numberOfPlayers])


useEffect(()=>{
  if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1) {
    setAuto(false);
    isIso(true);
    getIsoModal(true);
  }else{
    setAuto(true);
    isIso(false);
    getIsoModal(false);
  }
},[])

const history = useHistory();

function setHighScore(score){

  let singlePlayerDifficulty;

switch(difficulty) {
  case 0:
    singlePlayerDifficulty ="easy";
    break;
  case 1:
    singlePlayerDifficulty ="regular";
    break;
    case 2:
    singlePlayerDifficulty ="hard";
    break;
    case 3:
      singlePlayerDifficulty ="omniglot";
      break;
  default:
    return null;
} 
if(getCookie('token') !== ""){
axios.post(`${api}account/highscores`, {
          difficulty: `${singlePlayerDifficulty}`,
          highscore: score
        }, {
                headers: {
                    'Authorization': `bearerToken ${token}`,
                    'Content-Type': 'application/json'
                }
        })
        .then(response => {        
        })
        .catch(error => {
           if(error.response.data.message.includes("Token couldn't be validated")){
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            history.push("/signin");
            console.log('error')
          }
        });
      }
        }




useEffect(()=>{

    const Game = async () => {
      
      isAudioStopped(false);
        if(difChoosen){
        try {
          if(!nextLevel){
        const res = await fetch(`${api}question?currentPoints=${score}&difficultyIndex=${difficulty}`);
        const data = await res.json();
        getOptions(data.answers);
        getCorrect(data.correct);
        getPoints({points: data.points, level:data.level}); 
        getLevelPoints(data.max_points_for_level_at);  
        getNextLevel(true);
        getAudio(data.checksum);
        isLocked(false);
        getDetails(data.details);
        getSource(data.source);
        }} catch (error) {
          //console.log(error.message); 
        }
    }else{
            return null
        }
    }

      Game();
    },[difficulty, nextLevel]);



   const handleAnswer = (e) =>{
   const target = e.target.value;

     if(!stopAudio){
     isAudioStopped(true);
     setTimeout(function(){ isAudioStopped(false) }, 300);
      }

   if(target === correct){
            getScore(score + points);
            isLocked(true);
            isAnswerCorrect(true);
           e.target.classList.add("btn-success");
   }else{
            isLocked(true);
            e.target.classList.add("btn-danger");
            isAnswerCorrect(false);
            getLives(lives - 1)
        }
      }




    const removePlayer = (player) =>{
    let index = arrayOfPlayers.indexOf(player);
      if (index > -1) {
       arrayOfPlayers.splice(index, 1);
      }
    }
      
    useEffect(()=>{
     
      if(score >= 0 && name1 === null){
      setHighScore(score)}
    },[nextLevel])

    const getNext = (value) =>{ 
        const array = arrayOfPlayers;
        const index = array.indexOf(value);
        let order;
   
        if(playerOrder >= 0 && index < array.length - 1){
            order = arrayOfPlayers[index + 1]
              getOrder(order);
        }else{
            order = arrayOfPlayers[0]
            getOrder(order);
        }
        }    


useEffect(()=>{

const ifDead = () =>{
const dead = 0;

switch(true){
case(lives1 === dead):
        getPlayer1(values => ({...values,lives1: -1}));
        removePlayer(1);
        getDeadPlayer(name1);
break;
case(lives2 === dead):
    getPlayer2(values => ({...values,lives2: -1}));
    removePlayer(2);
    getDeadPlayer(name2);
 break;
case(lives3 === dead):
    getPlayer3(values => ({...values,lives3: -1}));
    removePlayer(3);
    getDeadPlayer(name3);
break;
case(lives4 === dead):
    getPlayer4(values => ({...values,lives4: -1}));
    removePlayer(4);
    getDeadPlayer(name4);
   break;
   default: 
  return null
}
}
ifDead();

},[lives1,lives2,lives3,lives4])


//if one of the players first reaches the score, end the game
useEffect(()=>{
const multiplayerScores = () =>{
if(multiScore !== 0){
if(score1 === multiScore || score2 === multiScore
 || score3 === multiScore || score4 === multiScore ){
getArray(0)
}else{
 return null
}}
}

multiplayerScores()
},[multiScore, score1,score2, score3, score4])


 const handleMultiAnswer = (e) =>{
   
  if(!stopAudio){
    isAudioStopped(true);
    setTimeout(function(){ isAudioStopped(false) }, 300);
     }

  
  const target = e.target.value; //get answer value 
   const boardLocked =()=>{
                isLocked(true);
   }

    //useState state setter function doesn't allow callback, so this is the way we are managing lives and scores for the multi-player mode  
   
    if(target === correct){
      isAnswerCorrect(true)
    }else{
      isAnswerCorrect(false)
    }


    switch (playerOrder) {
       case 0:
       case 1: //player 1
       boardLocked();
       getNext(1);
       getMultiOrder(1);
     //if answer correct
       if(target === correct){
        getPlayer1(values => ({...values, score1: score1 + points})); //add points
        e.target.classList.add("btn-success");//change button color
    //if answer wrong
      }else{
        e.target.classList.add("btn-danger");
       getPlayer1(values => ({...values, lives1: lives1 - 1})); // remove life
              }
         break;
   
         case 2: // player 2
         boardLocked();
         getNext(2);
         getMultiOrder(2);
           if(target === correct){
            getPlayer2(values => ({...values, score2: score2 + points}));
            e.target.classList.add("btn-success");
           }else{
            e.target.classList.add("btn-danger");
           getPlayer2(values => ({...values, lives2: lives2 - 1}));
                  }
             break;
   
             case 3: // player 3
             boardLocked();
             getNext(3);
             getMultiOrder(3);
               if(target === correct){
                getPlayer3(values => ({...values, score3: score3 + points}));
                e.target.classList.add("btn-success");
               }else{
                e.target.classList.add("btn-danger");
               getPlayer3(values => ({...values, lives3: lives3 - 1}));
                      }
                 break;
                 
                 case 4: // player 4
                 boardLocked();
                 //getOrder(1);
                 getMultiOrder(4);
                 getNext(4);
                   if(target === correct){
                    getPlayer4(values => ({...values, score4: score4 + points}));
                    e.target.classList.add("btn-success");
                   }else{
                    e.target.classList.add("btn-danger");
                   getPlayer4(values => ({...values, lives4: lives4 - 1}));
                          }
    }
   }
   
    return(
        <div>
        <Navbar />
        <div className="mt-5">   

        {gameMode ? null :  <GameMode getSinglePlayer={getSinglePlayer} getGameMode={getGameMode} /> }
        {gameMode && singlePlayer ? 
        <GameContext.Provider value={{handleAnswer,levelPoints, answerCorrect, getNextLevel, options, lockBoard, level, points, audioSource,correct,volume,setVolume,
        lives,iso, score,isoModal,getIsoModal, audio, details, singlePlayer, nextLevel,difficulty, getDifficulty, difChoosen, getLives, autoPlay, setAuto, stopAudio, isAudioStopped, isGameOver,gameOver}}>
        <SinglePlayer />
        </GameContext.Provider>
         : null}
      
        {!singlePlayer && gameMode ? 
        <GameContext.Provider value={{handleMultiAnswer,answerCorrect, getNextLevel, options,correct, lockBoard, setMultiScore, multiScore, audioSource,
        level, points, isoModal, getIsoModal, getLives,multiOrder, lives, score, audio, details, singlePlayer,name1,name2,name3,name4,score1,score2,score3,score4,
        getPlayer1, iso, getPlayer2, getPlayer3, getPlayer4, lives1, lives2, lives3, lives4, getNumberOfPlayers, numberOfPlayers, playerOrder,
        arrayOfPlayers,nextLevel, getDifficulty, difChoosen,volume,setVolume, autoPlay, setAuto, stopAudio, getGameType, gameType, deadPlayer, isGameOver,gameOver}}>
        <MultiPlayer />
         </GameContext.Provider>
         : null} 

        
         </div>

        </div>
        
    )
}