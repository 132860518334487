import React, {useState,useEffect, useContext} from "react";
import {getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { generalSearch} from "./Search.js";
import { AppContext } from "../../AppContext.js";

export default function Accounts(){

  const[datas, getDatas] = useState();
  const [loaded, isLoaded] = useState(false);
  
  const tableContext = useContext(AppContext);
  const table = 'accounts';

  useEffect(() =>{
    getTable(table,getDatas, isLoaded);
    },[tableContext.tableUpdated]);

  return(
    <>
    <h5 className="mt-5">Accounts <MDBIcon className="light-blue-text" icon="users" /></h5>  
    <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
  <MDBTableHead>
  <tr>
          <th><input className="form-control" id="myInput6" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
          <th><input className="form-control" id="myInput7" onChange={e => generalSearch(1,e.target.id)}  placeholder="search username" /></th>
          <th><input className="form-control" id="myInput8" onChange={e => generalSearch(2,e.target.id)}  placeholder="search email" /></th>
          <th><input className="form-control" id="myInput9" onChange={e => generalSearch(3,e.target.id)}  placeholder="search role" /></th>
          <th><input className="form-control" id="myInput10" onChange={e => generalSearch(4, e.target.id)}  placeholder="search country" /></th>
          <th><input className="form-control" id="myInput11" onChange={e => generalSearch(5,e.target.id)}  placeholder="search native lang" /></th>
        
        </tr>
    <tr style={{background:"#33b5e5", color:"white"}}>
      <th>Id</th>
      <th>Username</th>
      <th>Email</th>
      <th>Role</th>
      <th>Country</th>
      <th>Native language</th>
    </tr>
  </MDBTableHead>
  <MDBTableBody>
  {loaded ? 
  datas.map(data =>
  <tr key={data.id}>
  <td>{data.id}</td>
  <td>{data.username}</td>
  <td>{data.email}</td>
  <td>{data.role}</td>
  <td>{data.country.String}</td>
  <td>{data.native_language.String}</td>
</tr>
 ) 
  : null}
  </MDBTableBody>
</MDBTable>
    </>
    
)
}