import React, { useState, useContext, useEffect } from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {addData} from "../../../API.js";
import Alert from "./Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function LanguageModal(props){

const [error, getErr] = useState();
const [data ,setData]= useState({
  name: "",
  family: "",
  region: "",
  branch: "",
  subbranch: "",
  grp: "",
  subgroup: "",
  subsubgroup: "",
  variety: "",
  rank: "", 
  language_prop_id: ""
});

const table = 'languages';
const tableContext = useContext(AppContext);

  const handleChange = (name,value) => {
    setData({
      ...data,
      [name]: value
    });
  };

const refreshTable = () =>{
  addData(table,data,getErr);
  tableContext.getTableUpdate(true);
  setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
}

useEffect(()=>{
  if(!props.modal){
    getErr(undefined)
  }else{
    return
  }
},[props.modal])


return(
    <>
      <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Add language <MDBIcon className="light-blue-text" icon="language" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}>  
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} name="name" label="Name" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="family" label="Family"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} name="region" label="Region" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="branch" label="Branch" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="subbranch" label="Subbranch"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} name="grp" label="Grp" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="subgroup" label="Subgroup"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="subsubgroup" label="Subsubgroup" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="variety" label="Variety"/>
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} type="number" name="rank" label="Rank (number)" />
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} type="number" name="language_prop_id" label="Language_prop_id (number)"/>
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
        <MDBBtn color="info" value="submit" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
        </MDBModal>
    </>
)
}