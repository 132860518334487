import React, { useState } from "react";
import {MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem,
MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBBtn, MDBIcon} from "mdbreact";
import {withRouter} from 'react-router-dom';
import "./App.css";
import logo from './icon.png'
import {getCookie} from "./API.js";


function Navbar(){

const[open, isOpen] = useState(false);

let isLoggedIn= getCookie('token');

  return (
    <MDBNavbar color="info-color" dark expand="md">
        <MDBNavbarBrand>
          <MDBNavLink to="/">
          <img src={logo} style={{height:35, width: 35}} alt="logo" />
          </MDBNavLink>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={e=> isOpen(!open)} />
        <MDBCollapse id="navbarCollapse3" isOpen={open} navbar>
          <MDBNavbarNav left >
            <MDBNavItem id="link">
              <MDBNavLink  className="white-text" to="/">Home</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink id="link" className="white-text" to="/about">About</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
            <MDBNavLink id="link" className="white-text" to="/learn">Learn</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
          <MDBNavItem>
         {window.location.pathname === "/game"  ? 
                   <MDBNavLink to={isLoggedIn === "" ? "/signin" : "/account" }>
                   <MDBBtn  id="nav-btn" className="blue-font mb-2
                    font-weight-bolder" style={{fontSize: 13,borderRadius: 25,
                     padding:"8px 20px", marginTop: "5px"}} color="white">
                       {isLoggedIn === "" ? "Sign In" : <MDBIcon icon="user-alt" />}
                       </MDBBtn>
                   </MDBNavLink>
         :
          <MDBNavLink to="/game">
            <MDBBtn  id="nav-btn" className="blue-font mb-2
             font-weight-bolder" style={{fontSize: 13,borderRadius: 25,
              padding:"8px 20px", marginTop: "5px"}} color="white">Play</MDBBtn>
            </MDBNavLink>}
            </MDBNavItem>
            {isLoggedIn === "" || window.location.pathname === "/account" 
            || window.location.pathname === "/game"  ? null :
            <MDBNavLink to="/account" >
                   <MDBBtn  id="nav-btn" className="blue-font mb-2
                    font-weight-bolder" style={{fontSize: 13,borderRadius: 25,
                     padding:"8px 20px", marginTop: "5px"}} color="white"><MDBIcon icon="user-alt" />
                       </MDBBtn>
                   </MDBNavLink>}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }

export default withRouter(Navbar);