import React, {useState, useEffect, useContext} from "react";
import { generalSearch} from "./Search.js";
import {getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { AppContext } from "../../AppContext.js";

export default function LanguageProps(){

    const[datas, getDatas] = useState();
    const [loaded, isLoaded] = useState(false);
  
    const tableContext = useContext(AppContext);
    const table = 'language_props';

    useEffect(() =>{
      getTable(table,getDatas, isLoaded);
      },[tableContext.tableUpdated]);
  
  
      return(
          <>
           <h5 className="mt-5">Language Props <MDBIcon className="light-blue-text" icon="language" /></h5>  
           <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
  <MDBTableHead style={{textTransform: "capitalize"}}>
  <tr>
          <th><input className="form-control" id="myInput32" onChange={e => generalSearch(0,e.target.id)} placeholder="id" /></th>
          <th><input className="form-control" id="myInput33" onChange={e => generalSearch(1,e.target.id)}  placeholder="Autonym" /></th>
          <th><input className="form-control" id="myInput34" onChange={e => generalSearch(2,e.target.id)}  placeholder="transliteration" /></th>
          <th><input className="form-control" id="myInput35" onChange={e => generalSearch(3, e.target.id)}  placeholder="Ipa" /></th>
          <th><input className="form-control" id="myInput36" onChange={e => generalSearch(4,e.target.id)}  placeholder="official_in" /></th>
          <th><input className="form-control" id="myInput37" onChange={e => generalSearch(5,e.target.id)} placeholder="recognized_minority_in" /></th>
          <th><input className="form-control" id="myInput38" onChange={e => generalSearch(6,e.target.id)}  placeholder="recognized_minority_in" /></th>
          <th><input className="form-control" id="myInput39" onChange={e => generalSearch(7,e.target.id)}  placeholder="total_speakers" /></th>
          <th><input className="form-control" id="myInput40" onChange={e => generalSearch(8, e.target.id)}  placeholder="native_speakers" /></th>
          <th><input className="form-control" id="myInput41" onChange={e => generalSearch(9,e.target.id)}  placeholder="l2_speakers" /></th>
          <th><input className="form-control" id="myInput42" onChange={e => generalSearch(9,e.target.id)}  placeholder="spoken_in" /></th>
        </tr>
    <tr style={{background:"#33b5e5", color:"white"}}>
      <th>Id</th>
      <th>Autonym</th>
      <th>transliteration</th>
      <th>Ipa</th>
      <th>official_in</th>
      <th>recognized_minority_in</th>
      <th>significant_minority_in</th>
      <th>total_speakers</th>
      <th>native_speakers</th>
      <th>l2_speakers</th>
      <th>spoken_in</th>
    </tr>
  </MDBTableHead>
  <MDBTableBody>
  {loaded ? 
  datas.map(data =>
  <tr key={data.id}>
  <td>{data.id}</td>
  <td>{data.autonym}</td>
  <td>{data.transliteration}</td>
  <td>{data.ipa}</td>
  <td>{data.official_in}</td>
  <td>{data.recognized_minority_in}</td>
  <td>{data.significant_minority_in}</td>
  <td>{data.total_speakers}</td>
  <td>{data.native_speakers}</td>
  <td>{data.l2_speakers}</td>
  <td>{data.spoken_in}</td>
</tr>
  ) 
  : null}
  </MDBTableBody>
</MDBTable>
          </>         
      )
  }