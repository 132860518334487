import React, { useEffect, useRef, useState, useContext} from "react";
import WaveSurfer from "wavesurfer.js";
import "./game.css";
import { MDBIcon, MDBPopover, MDBPopoverBody, MDBBtn }  from 'mdbreact';
import { GameContext } from "./Context.js";

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "#33b5e5",
  cursorColor: "#33b5e5",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 150,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});



export default function AudioPlayer(props) {

  const game = useContext(GameContext);
  const api = process.env.REACT_APP_API_URL;
  const url= `${api}sounds/` + props.audio

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(true);


  const autoPlay= game.autoPlay;
  const setAuto = game.setAuto;
  const volume= game.volume;
  const setVolume= game.setVolume;
 

  const handlePlayPause = () => {
    setPlay(!playing);

    wavesurfer.current.playPause();

if (game.iso){
   iosAudio();
 } 
  };

  useEffect(() =>{
    if(game.stopAudio){
     //handlePlayPause();
     wavesurfer.current.pause();
     setPlay(false)
    }else{
      return
    }
  },[game.stopAudio])


  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

   wavesurfer.current.load(url);
   wavesurfer.current.on('error', (e) => { game.getNextLevel(false) });


    wavesurfer.current.on("ready", function() {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
        if (wavesurfer.current) {
        if (autoPlay) {
          handlePlayPause();
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
        }
      }else{
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
      }
    });

    wavesurfer.current.on('finish', function () {
     setPlay(false);
  });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);



	function iosAudio(){

		var audioios = document.createElement('audio');
		audioios.id = 'audio-player-iosfix';
		audioios.src = url;
		audioios.type = 'audio/mpeg';

	    audioios.play();
	    audioios.remove();
		playthat();
	}

  function playthat() {
	    wavesurfer.current.play();
	}


  const onVolumeChange = e => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <>
<div id="settings" className="d-flex justify-content-end">
        <MDBPopover
          placement="bottom"
          popover
          clickable
          id="popper3"
        >
          <MDBBtn id="btn" className="align-self-end" style={{borderRadius:"50%"}}><MDBIcon icon="cog" /></MDBBtn>
          <div className="text-center">
            <MDBPopoverBody>
            <input
          type="range"
          id="volume"
          name="volume"
          // waveSurfer recognize value of `0` same as `1`
          //  so we need to set some zero-ish value for silence
          min="0.01"
          max="1"
          step=".025"
          onChange={onVolumeChange}
          defaultValue={volume}
        />
        <label className="ml-1" htmlFor="volume"><MDBIcon style={{color: "#0099CC"}} icon="volume-up" /></label>
     <div className='custom-control custom-switch ml-2'>
        <input
          type='checkbox'
          className='custom-control-input'
          id='customSwitchesChecked'
          defaultChecked={autoPlay}
          onClick={e => setAuto(!autoPlay)}
        />
        <label className='custom-control-label' htmlFor='customSwitchesChecked'>
         AutoPlay
        </label>
      </div> 
            </MDBPopoverBody>
          </div>
        </MDBPopover>
        </div>
    <div style={{display:"flex", justifyContent:"center"}}>
    <button id="playbtn" onClick={handlePlayPause}>{!playing ? <MDBIcon icon="play" /> : <MDBIcon icon="pause" />}</button>
      <div id="waveform" ref={waveformRef} />
    </div>

    </>
  );
}

