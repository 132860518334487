import React, { useState ,useContext } from "react";
import { GameContext } from "./Context.js";
import { MDBAnimation, MDBBtn, MDBInput, MDBIcon} from "mdbreact";
import "./game.css";
import { Link } from "react-router-dom";
import { getCookie } from "../../API.js";
import axios from 'axios';
import {api} from "../../API.js";
import DonateModal from "../DonateModal.js";

export default function GameOver(){
    
    const game = useContext(GameContext);

    const score = game.score;
    const [username, getUsername] = useState();
    const [save, isSaved]= useState();

    let token = getCookie('token');
    let difficulty;

    switch(game.difficulty) {
        case 0:
          difficulty ="easy";
          break;
        case 1:
            difficulty ="regular";
          break;
          case 2:
            difficulty ="hard";
          break;
          case 3:
            difficulty ="omniglot";
            break;
        default:
          return null;
      } 

const saveScore = () =>{
    axios.post(`${api}highscores`, {
        difficulty: difficulty,
        highscore: score,
        name: `${username}`
      }, {
              headers: {
                  'Content-Type': 'application/json'
              }
      })
      .then(response => {  
        isSaved(true);  
      })
      .catch(error => {
      }); 
      console.log(save)
    }

    const playAgain =(e) =>{
        window.location.reload();
        return false;
    }
   

    return(
        <>
        {game.singlePlayer ? 
        <>
           <MDBAnimation type="zoomIn">
        <div className="pb-5" style={{marginTop:"4%"}}>
        <h1 className="font-weight-bold">GAME OVER</h1>
        <h3>Your score is <strong>{game.score}</strong></h3>

        {token === "" ?
        <div className={save ? "d-none" :"d-flex justify-content-center flex-column" }>
        <p className="blue-text">You are not logged in,<br/> if you want to claim your highscore, choose a username</p>
        <div className="form-group d-flex flex-row" style={{margin: "auto", width:"200px", marginTop:"-20px"}}>
        <MDBInput label="Username" size="sm" type="text" onChange={e=> getUsername(e.target.value)} /> 
        <MDBBtn color="primary" onClick={e=> saveScore()} style={{width:40, height: 40, borderRadius: "50%", padding: 0, margin: "auto"}}>
        <MDBIcon far icon="paper-plane" />
        </MDBBtn>
    </div>
        </div> : null }

        <MDBBtn color="primary" onClick={playAgain}>Play Again</MDBBtn>
        <Link to="/highscores"><MDBBtn color="primary" >HighScores</MDBBtn></Link>
        </div>
        </MDBAnimation>
        </> 
        : <>
        <MDBAnimation type="zoomIn">
        <div style={{marginTop:"10%"}}>
        <h1 className="font-weight-bold">GAME OVER</h1>
        
        <h3>{game.name1} : {game.score1}</h3>

        <h3>{game.name2} : {game.score2}</h3>

        <h3 style={{display: game.name3 === null ? 'none' : ''}}>
            {game.name3} : {game.score3}</h3>

        <h3 style={{display: game.name4 === null ? 'none' : ''}}>
            {game.name4} : {game.score4}</h3>

            <MDBBtn color="primary" onClick={playAgain}>Play Again</MDBBtn>
            <Link to="/highscores"><MDBBtn color="primary" >HighScores</MDBBtn></Link>
        </div> 
        </MDBAnimation>
        
        </>
}

<DonateModal modal={true} />
        </>
    )
}