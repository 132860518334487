import React, {useState, useEffect} from "react";
import { MDBContainer, MDBRow, MDBCard, MDBInput, MDBAnimation, MDBBtn} from 'mdbreact';
import Navbar from "../../Navbar.js";
import { Link } from "react-router-dom";
import { forgottenPassword, passEmailSent } from "../../API.js";

export default function Password(){

    const[email, getEmail]= useState('');
    const[clicked, isClicked]= useState(false);

    let success;

useEffect(()=>{
    if(clicked){
   setTimeout(function(){ isClicked(!clicked) }, 3000);
   console.log(success)
    }
},[clicked])

return(
    <div>
                <Navbar />
        <MDBAnimation type="fadeIn">
        <MDBContainer>
  <MDBRow className="w-100 d-flex justify-content-center ml-0 mr-0">
    <MDBCard id="card" className="mt-5 mb-1 d-flex flex-column justify-content-center pt-2">
        <div className="pt-3 mb-3" style={{width:"90%", marginLeft:"5%"}}>
    { passEmailSent != undefined ?
    <h5><strong>{passEmailSent ?  "Check your email" :  "There has been an error"}</strong></h5> : 
    <>
    <h5><strong>Forgot your password?</strong></h5>
    <p>Please enter the email you use to sign in to your account</p>
    <div style={{width:"80%", marginLeft:"10%"}}>
    <MDBInput type="text" onChange={e => getEmail(e.target.value)} label="Your email" />
    </div>
    <MDBBtn color="primary" onClick={(e) =>{ forgottenPassword(email); isClicked(true)}}>Reset password</MDBBtn>
    <p className="mt-2">
        <strong><Link to="/signin"><sup className="blue-text">Back to Sign In</sup></Link></strong>
        </p>
        </>
}
    </div>
    </MDBCard>
    </MDBRow> 
    </MDBContainer>
    </MDBAnimation>   
    </div>
)
}