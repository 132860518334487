import React,{useState} from "react";
import { MDBContainer, MDBRow, MDBCard, MDBInput, MDBAnimation, MDBBtn} from 'mdbreact';
import Navbar from "../../Navbar.js";
import { Link } from "react-router-dom";
import { changePassword } from "../../API.js";


export default function NewPassword(){

    const[password,getPassword]= useState('');
    const[password2,getPassword2]= useState('');
 
    //this part gets the hash from the link
    let href = window.location.href;
    href.slice(0, href.lastIndexOf('/') + 1);
    let hash = href.slice(href.lastIndexOf('/') + 1);


return(
    <div>
                <Navbar />
        <MDBAnimation type="fadeIn">
        <MDBContainer>
  <MDBRow className="w-100 d-flex justify-content-center ml-0 mr-0">
    <MDBCard id="card" className="mt-5 mb-1 d-flex flex-column justify-content-center pt-2">
        <div className="pt-3 mb-3" style={{width:"90%", marginLeft:"5%"}}>
    <h5><strong className="blue-text">Change Password</strong></h5>
    <div style={{width:"80%", marginLeft:"10%"}} className="text-left grey-text">
    <MDBInput onChange={e=> getPassword(e.target.value)} label="New password" 
    className={password === password2 ? "form-control is-valid" : "form-control is-invalid"}
    icon="lock" group type="password" validate error="wrong" success="right" />
    <MDBInput className={password === password2 ? "form-control is-valid" : "form-control is-invalid"} onChange={e=> getPassword2(e.target.value)}  label="Confirm new password" 
    icon="key" group type="password" validate   error="wrong" success="right" />
    </div>
    <MDBBtn disabled={password === password2 ? false : true} onClick={e=>changePassword(password,hash)} color="primary">Change Password</MDBBtn>
    <p className="mt-2">
        <strong><Link to="/signin"><sup className="blue-text">Back to Sign In</sup></Link></strong>
        </p>
    </div>
    </MDBCard>
    </MDBRow> 
    </MDBContainer>
    </MDBAnimation>   
    </div>
)
}