import React, {useState, useEffect, useContext} from "react";
import { generalSearch} from "./Search.js";
import { getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { AppContext } from "../../AppContext.js";

export default function Game(){

    const[datas, getDatas] = useState();
    const [loaded, isLoaded] = useState(false);
    
    const tableContext = useContext(AppContext);
    const table = 'levels';

    useEffect(() =>{
      getTable(table,getDatas, isLoaded);
      },[tableContext.tableUpdated]);
  
  
      return(
          <>
           <h5 className="mt-5">Game <MDBIcon className="light-blue-text" icon="dice" /></h5>  
           <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
  <MDBTableHead>
  <tr>
          <th><input className="form-control" id="myInput11" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
          <th><input className="form-control" id="myInput12" onChange={e => generalSearch(1,e.target.id)}  placeholder="answer_max" /></th>
          <th><input className="form-control" id="myInput13" onChange={e => generalSearch(2,e.target.id)}  placeholder="answer_min" /></th>
          <th><input className="form-control" id="myInput14" onChange={e => generalSearch(3, e.target.id)}  placeholder="groups_on" /></th>
          <th><input className="form-control" id="myInput15" onChange={e => generalSearch(4,e.target.id)}  placeholder="hard_for_level" /></th>
          <th><input className="form-control" id="myInput16" onChange={e => generalSearch(5,e.target.id)} placeholder="max_points_for_level" /></th>
          <th><input className="form-control" id="myInput17" onChange={e => generalSearch(6,e.target.id)}  placeholder="max_ranks" /></th>
          <th><input className="form-control" id="myInput18" onChange={e => generalSearch(7,e.target.id)}  placeholder="mult_answers" /></th>
          <th><input className="form-control" id="myInput19" onChange={e => generalSearch(8, e.target.id)}  placeholder="mult_rank" /></th>
          <th><input className="form-control" id="myInput20" onChange={e => generalSearch(9,e.target.id)}  placeholder="mult_relation" /></th>
        </tr>
    <tr style={{background:"#33b5e5", color:"white"}}>
      <th>Id</th>
      <th>Answer_max_for_level</th>
      <th>Answer_min_for_level</th>
      <th>Groups_on</th>
      <th>Hard_for_level</th>
      <th>Max_points_for_level</th>
      <th>Max_ranks</th>
      <th>Mult_answers</th>
      <th>Mult_rank</th>
      <th>Mult_relation</th>
    </tr>
  </MDBTableHead>
  <MDBTableBody>
  {loaded ? 
  datas.map(data =>
  <tr key={data.id}>
  <td>{data.id}</td>
  <td>{data.answer_max_for_level}</td>
  <td>{data.answer_min_for_level}</td>
  <td>{data.groups_on}</td>
  <td>{data.hard_for_level}</td>
  <td>{data.max_points_for_level}</td>
  <td>{data.max_ranks}</td>
  <td>{data.mult_answers}</td>
  <td>{data.mult_rank}</td>
  <td>{data.mult_relation}</td>
</tr>
  ) 
  : null}
  </MDBTableBody>
</MDBTable>
          </>         
      )
  }