import React from "react";
import {Link} from "react-router-dom";
import { MDBIcon, MDBCard, MDBCardBody,MDBCardTitle, MDBCardText, MDBCol} from 'mdbreact';

export default function Articles(props){

    const title = props.title;
    const url = props.url;
    const subject = props.subject;
    const thumbnail_url = props.title;
    return(
<ul>
            <MDBCol className="text-left w-100">
      <MDBCard>
      <MDBCardBody className="text-left">
            <Link to={{ pathname: '/article/' + thumbnail_url,  
             article: {thumbnail_url, url}
           }}> <MDBCardTitle>{title} </MDBCardTitle></Link>
            <MDBCardText>{subject}</MDBCardText>
            <Link to={{ pathname: '/article/' + thumbnail_url,  
             article: {thumbnail_url, url}
           }}> 
           <h6 className="blue-text font-weight-bold">Read more 
            <MDBIcon style={{fontSize:15, marginTop:"-10px"}} className="ml-1" icon="angle-double-right" />
           </h6></Link>
            </MDBCardBody>
            </MDBCard>
    </MDBCol>
          </ul>
    )
}