import React, { useEffect, useState, useContext } from "react";
import { MDBBtn, MDBIcon, MDBCard, MDBCardTitle, MDBContainer, MDBRow,MDBModalBody,MDBModal, MDBAnimation, MDBTooltip} from "mdbreact";
import AudioPlayer from "./AudioPlayer.js";
import { GameContext } from "./Context.js";
import "./game.css";

export default function Play(props){

//const [answered, isAnswered] = useState(false);
const [count, getCount] = useState(0);
const [languageDetails, getDetails] = useState();
const [animate, isAnimated] = useState(true);

const game = useContext(GameContext);

 const answerChosen = (e) =>{
  const btn = document.querySelectorAll(`button[value="${game.correct}"]`);
  btn[0].style.background="#00C851";
  btn[0].style.color="white";

   if(game.singlePlayer){
   game.handleAnswer(e);
  //  isAnswered(true);
   }else{
    game.handleMultiAnswer(e);
  //  isAnswered(true);
   }
 }

 //get keyboard pressed key 
 document.onkeydown = function(e) {
  let x = e.which || e.keyCode;
  onKeyPress(x);
}

//on key press set the answer
const onKeyPress = (x) =>{
  const checkBtn = (key) =>{
  const btn = document.getElementById(`${key}`);
 
  if(btn === null){ //if there isn't a button with the same id do nothing
    return null
   }else{ //if there is, click the button
    btn.click();
   }
  }


  switch(x){
    case 32:
      //next button
      checkBtn('next');
      break;
    case 49:
      //1 answer
      checkBtn(0);
      break;
    case 50:
      //2
      checkBtn(1);
      break;
    case 51:
      //3
      checkBtn(2);
      break;
    case 52:
      //4
      checkBtn(3);
      break;
    case 53:
      //5
      checkBtn(4);
      break;
    case 54:
      //6
      checkBtn(5);
      break; 
    case 55:
        //7
      checkBtn(6);
      break; 
    case 56:
      //8
      checkBtn(7);
      break;         
    case 57:
      //8
      checkBtn(8);
      break; 
    case 48:
      //9
      checkBtn(9);
      break;
      default:
      return  null
  }
}


const onclick = (e) =>{
  getDetails(game.details[e.target.value])
  getCount(count + 1);
if(count === 0){
  answerChosen(e);
}else{
  return null;
}
}


useEffect(()=>{

  isAnimated(true);

//   if(game.lives > 0 || game.arrayOfPlayers.length !== undefined || game.arrayOfPlayers.length > 1){
//  setTimeout(function(){ isAnimated(false); }, 1000);
//   }

  if(game.gameOver){
    setTimeout(function(){ isAnimated(false); }, 1000);
     }

  if(count >= 1){
    var scrollingElement = (document.scrollingElement || document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }

 // console.log(languageDetails);
  return () => {
    isAnimated(false);
  };
},[count])


   var availableOptions = game.options.map((option,i )=>{
     return <button type="button" style={{opacity : count >= 1 ?  "0.7" : "1"}} className="btn" 
   key={i} id={i} onClick={e => onclick(e)} value={option} >{option} {count > 0 ? <MDBIcon icon="info-circle" /> : ""}</button>
  });

  const nextLvl = () =>{
    if(game.arrayOfPlayers !== undefined){
    if(game.arrayOfPlayers.length < 2){
      game.isGameOver(true)
    }else{
      game.getNextLevel(false)
    }
  }else{
     game.getNextLevel(false)
  }

  if(game.lives < 1 ){
    game.isGameOver(true)
  }else{
    game.getNextLevel(false)
  }
  }


    return(
      <MDBAnimation type="fadeIn">
        <div style={{overflowX:"hidden"}} id="play">
        <MDBModal isOpen={game.isoModal} size="sm">
    <MDBIcon far icon="times-circle" className="grey-text pt-2 pr-2 text-right" style={{cursor:"pointer"}}
        onClick={e => game.getIsoModal(false)} />
        <MDBModalBody>
          If you are using Safari, please enable autoplay on your browser so you can play the game.
        </MDBModalBody>
      </MDBModal> 
    <AudioPlayer autoPlay audio={game.audio} />

    <MDBContainer className="mt-2">
      {count >= 1 ? 
      <div className="d-flex flex-column">
        <MDBTooltip
          domElement
          tag="span"
          placement="top"
        >
          <span style={{cursor:"pointer"}} className="blue-text">Source <MDBIcon icon="info-circle" /></span>
          <span>{game.audioSource}</span>
        </MDBTooltip>
        <span  className="blue-text mt-2">Click on a language to learn more </span>
        </div>: "" }
        </MDBContainer>
        <div className="w-100 d-flex justify-content-center">
             <div id="options" className="mt-2 d-flex justify-content-center flex-wrap">
            {availableOptions}
            </div>
</div>
            <br/>
            
            <MDBBtn color="primary" id="next" disabled={game.lockBoard ? false : true } onClick={ e => nextLvl()} className="mt-2">
                <MDBIcon icon="angle-double-right" />
                </MDBBtn>

              <MDBRow className="d-flex flex-column justify-content-center pb-2">
                {props.name1}
             {count >= 2 ?  <>
            {languageDetails === undefined ? "" : <>
             <MDBContainer className= {animate? 'text-left fade-in' : 'text-left'}>
             <MDBCard className="card-body w-100" style={{ marginTop: "1rem" }}>
             <MDBCardTitle className="blue-text"><strong>{languageDetails.language === undefined ? "" : languageDetails.language}</strong></MDBCardTitle> 
            {languageDetails.autonym !== undefined ? <h6> <strong>Autonym:</strong> {languageDetails.autonym}</h6> : null}
            {languageDetails.native_speakers !== undefined ?<h6><strong>Native speakers:</strong> {languageDetails.native_speakers}</h6> : null}
            {languageDetails.official_in !== undefined ? <h6><strong>Official in:</strong> {languageDetails.official_in}</h6> : null}
            {languageDetails.transliteration !== undefined ? <h6><strong>Transliteration:</strong> {languageDetails.transliteration}</h6> : null}
            {languageDetails.classification !== undefined ? <h6><strong>Classification:</strong> {languageDetails.classification.join(", ")}</h6> : null}            
            </MDBCard>
            </MDBContainer>
            </>}
            </>
              : null}
             </MDBRow>  
        </div>
        </MDBAnimation>
    )
}