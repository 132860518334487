import React, {useState, useEffect, useContext} from "react";
import { MDBContainer, MDBRow, MDBBtn, MDBInput, MDBCard, MDBAnimation } from 'mdbreact';
import {Link} from 'react-router-dom';
import Navbar from "../../Navbar.js";
import "./login.css";
import LogIn, {message} from "../../API.js";
import  { Redirect } from 'react-router-dom'
import { AppContext } from "../../AppContext.js";
import Spinner from "../../Spinner.js";

const SignIn = () => {

const account = useContext(AppContext);

const[email, getEmail] = useState();
const[password, getPassword] = useState();
const[click, isClicked] = useState(false);
const[loading,isLoading]=useState(false);

const whenClicked = (e,p) =>{
  LogIn(e,p);
  setTimeout(function(){ 
  isClicked(true);
  isLoading(true);
}, 100);
setTimeout(function(){ 
  isClicked(false);
  isLoading(false);
}, 1000);
}


useEffect(()=>{
  setTimeout(function(){ 
    account.getRerender(!account.rerender);
  }, 500);
  
},[click,account.account])

useEffect(()=>{
  setTimeout(function(){ 
    if(message == "Login failed, wrong user credentials!"){
      isLoading(false)
    }
  }, 500);
},[click])

return (
  <>
  {account.account === "" ? 
  <>
{loading ? 
 <Spinner />
 :
<>
    <Navbar />
    <MDBAnimation type="fadeIn">
<MDBContainer>
  <MDBRow className="w-100 d-flex justify-content-center">
    <MDBCard id="card" className="mt-5 mb-2" style={{marginRight:0, marginLeft: 0}} >
      <form className="mb-0 mt-4">
        <h3 className="text-center mb-5 blue-text">Log In</h3>
        <div className="grey-text text-left">
          <MDBInput label="Your email" icon="envelope" group type="email" onChange={e => getEmail(e.target.value)} validate error="wrong"
            success="right" />
          <MDBInput label="Your password" onChange={e => getPassword(e.target.value)} icon="lock" group type="password" validate />
          </div>
        <div className="text-center">
          <p className="deep-orange-text" >{message !== undefined ?  message : "" }</p>
          <MDBBtn color="primary" onClick={e =>whenClicked(email,password)}>Log In</MDBBtn>
        </div>
      </form>
      <MDBRow className="d-flex flex-row justify-content-around font-weight-bold pt-3 mb-3">
<p style={{cursor:"pointer"}}><Link to="/signup" ><sub className="blue-text">Register</sub></Link></p>
<p style={{cursor:"pointer"}}><Link to="/password"><sub className="blue-text">Forgot your password?</sub></Link></p>
</MDBRow>
    </MDBCard>
  </MDBRow>
</MDBContainer>
</MDBAnimation>
</>} </> : <Redirect to='/account'  />}
</>
);
};

export default SignIn;