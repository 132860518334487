import React from "react";
import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader} from 'mdbreact';

export default function InfoBox(props){


const setFootnote = () => props.setFootnote;
    return(

                <MDBContainer>
                {/* MODAL */}
                <MDBModal isOpen={props.show} backdrop={false} size="sm" className="modal-dialog modal-dialog-scrollable" side  position="top-left">
                  <MDBModalHeader toggle={setFootnote({show:false})}></MDBModalHeader>
                  <MDBModalBody>
                  {props.footnote}
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
    )
}