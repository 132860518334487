import React, {useEffect, useState, useContext} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {deleteRow} from "../../../API.js";
import { AppContext } from "../../../AppContext.js";
import Alert from "./Alert.js";

export default function DeleteModal(props){

const[table, getTable] = useState("");
const[id, getId]= useState();
const tableContext = useContext(AppContext);
const [error, getErr] = useState();

useEffect(() =>{
    switch(props.page) {
        case 'audio':
          getTable('sounds');
          break;
        case 'game':
            getTable('levels');
          break;
          case 'articles':
            getTable('articles');
          break;
          case 'language':
            getTable('languages');
          break;
          case 'langprops':
            getTable('language_props');
          break;
          case 'accounts':
            getTable('accounts');
          break;
          case 'highscores':
            getTable('highscores');
          break;
        default:
            //getTable('sounds');
            return
      }
},[props.page]);


const refreshTable = () =>{
  deleteRow(table, id, getErr); 
  tableContext.getTableUpdate(true);
  setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
}

useEffect(()=>{
  if(!props.modal){
    getErr(undefined)
  }else{
    return
  }
},[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Delete <MDBIcon className="light-blue-text"  icon="trash-alt" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
      <MDBInput label="Id" onChange={e => getId(e.target.value)} type="number"/>
        </MDBModalBody>
        <Alert error={error} getErr={getErr}/>
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}