import React from "react";
import "./spinner.css";

export default function Spinner(){
    return(
        <div className="loader">       
  <div className="line"></div>
  <div className="line"></div>
  <div className="line"></div>
  <div className="line"></div>
</div>
    )
}