import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

export default function OmniglotScores(props){

    return(
 <>       
        <MDBTable bordered={true} striped={true}  className="mt-3" style={{width:"60%", marginLeft: "20%"}}>
      <MDBTableHead color="info-color" textWhite>
        <tr>
          <th>#</th>
          <th>Username</th>
          <th>High Score</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      <tr>
          <td>1</td>
          <td>{ props.data !== undefined && props.data[0] !== undefined ?  props.data[0].name : "--"}</td>
          <td>{ props.data !== undefined && props.data[0] !== undefined ?  props.data[0].score : "--"}</td>
        </tr>
        <tr>
          <td>2</td>
          <td>{ props.data !== undefined && props.data[1] !== undefined ?  props.data[1].name : "--"}</td>
          <td>{ props.data !== undefined && props.data[1] !== undefined ?  props.data[1].score : "--"}</td>
        </tr>
        <tr>
          <td>3</td>
          <td>{ props.data !== undefined && props.data[2] !== undefined ?  props.data[2].name : "--"}</td>
          <td>{ props.data !== undefined && props.data[2] !== undefined ?  props.data[2].score : "--"}</td>
        </tr>
        <tr>
          <td>4</td>
          <td>{ props.data !== undefined && props.data[3] !== undefined ?  props.data[3].name : "--"}</td>
          <td>{ props.data !== undefined && props.data[3] !== undefined ?  props.data[3].score : "--"}</td>
        </tr>
        <tr>
          <td>5</td>
          <td>{ props.data !== undefined && props.data[4] !== undefined ?  props.data[4].name : "--"}</td>
          <td>{ props.data !== undefined && props.data[4] !== undefined ?  props.data[4].score : "--"}</td>
        </tr>
      </MDBTableBody>
    </MDBTable>
        </>
    )
}