import React, {useEffect, useState , useContext}  from "react";
import { MDBContainer, MDBRow, MDBBtn, MDBInput, MDBCard, MDBAnimation } from 'mdbreact';
import {Link} from 'react-router-dom';
import Navbar from "../../Navbar.js";
import "./login.css";
import {createAccount, signUpSucces, getLang, newUser} from "../../API.js";
import DropDown from "./Country.js";
import NativeLang from "./NativeLang.js";
import AdditionalLang from "./AdditionalLang.js";
import { AppContext } from "../../AppContext.js";
import ModalSignUp from "./ModalSignUp.js";


const SignUp = () => {

const account = useContext(AppContext);

const[username,getUsername]= useState();
const[email,getEmail]= useState();
const[country,getCountry]= useState("Afghanistan");
const[lang,getLanguage]= useState('Afrikaans');
const[password, getPassword] = useState();
const[password2,getPassword2]= useState();
const[click, isClicked]= useState(false);
const[moreLangs, getLangs]= useState();
const[options, getOptions] = useState([]);

const whenClicked = (c, e, l, p, u, m) =>{
  createAccount(c, e, l, p, u, m);
  setTimeout(function(){ 
  isClicked(!click);
}, 100);
}

  
async function languages() {
  const langs= await getLang();
  const arr =  langs.map(lang => ({ value: lang.name, label: lang.name }))
  getOptions(arr);
}


useEffect(()=>{
  account.getRerender(!account.rerender);
  languages();
},[click])

useEffect(()=>{
 //console.log(moreLangs)
},[moreLangs])


return (
  <>
    <Navbar />
    <ModalSignUp open={signUpSucces}/>
    <MDBAnimation type="fadeIn">
<MDBContainer className="pb-1">
  <MDBRow className="w-100 d-flex justify-content-center">
    <MDBCard id="card" className="mt-5 mb-2">
      <form className="mt-2 mb-2">
        <h3 className="text-center mb-4 mt-2 blue-text">Sign up</h3>
        <div className="grey-text text-left">
          <MDBInput label="Username" icon="user" onChange={e => getUsername(e.target.value)}
           group type="text" validate error="wrong" className={ !signUpSucces === "Bad Request" ? "form-control is-invalid" : ""}
            success="right" required/>
      
           <DropDown getCountry={getCountry} />
           <NativeLang getLang={getLanguage} lang={lang}/>    
           

          <AdditionalLang getLangs={getLangs} options={options} />
           <MDBInput id="email123" label="Your email" icon="envelope" group type="email" validate error="wrong"
           className={ !signUpSucces ? "form-control is-invalid" : ""}
            success="right" onChange={e => getEmail(e.target.value)} required/>

          <MDBInput label="Your password" icon="lock" group type="password" validate 
          className={password2 === password  ? "form-control is-valid" : "form-control is-invalid"}
          onChange={e=> getPassword(e.target.value)} required/>

          <MDBInput label="Confirm your password" icon="key" group type="password" validate 
          error="wrong" success="right" 
          className={password2 === password ? "form-control is-valid" : "form-control is-invalid"}
           onChange={e => getPassword2(e.target.value)} required/>
        </div>
        <p className="red-text">{signUpSucces || signUpSucces === undefined ? "" : "Please check your entry and try again"}</p>
        <div className="text-center">
          <MDBBtn color="primary" onClick={e => whenClicked(country, email, lang, password, username, moreLangs)} >Register</MDBBtn>
        </div>
        <p style={{cursor:"pointer"}}><Link to="/signin"><sub className="blue-text">Already have an account?</sub></Link></p>
      </form>

    </MDBCard>
  </MDBRow>
</MDBContainer>
</MDBAnimation>

</>
);
};

export default SignUp;