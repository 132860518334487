import React from 'react';
import Select from 'react-select';
import { css } from '@emotion/react';

export default class AdditionalLang extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          selectedOption: null,
          onFocusChange: false
        }
      }
        handleChange = selectedOption => {
          this.setState({ selectedOption });
         // console.log(`Option selected:`, selectedOption);
          let selected = selectedOption.map(item => (item.label))
          this.props.getLangs(selected.toString());
        };

  render() {

  const { selectedOption } = this.state;

      return (
        <div className="mt-3">
        <label>I also speak</label>
      <Select
         css={css`
         color: green;
       `}
        value={selectedOption}
        onChange={this.handleChange}
        options={this.props.options}
        isMulti={true}
      />
      </div>
    );
  }
}
