import React, {useEffect, useState} from  "react";
import { MDBInput } from 'mdbreact';
import {getLang} from "../../API.js";

export default function NativeLang(props){

const[languages, getLanguages] = useState()
const[dropDownValue, getDropDownValue] = useState();

useEffect(() =>{
     async function languages() {
        const data= await getLang();
        getLanguages(data);
      }
      
     languages();
    },[props.lang]);


return(
        <div className="mt-2">
        {languages !== undefined ? 
        <>
        <label>Native Language</label>
        <select className="browser-default custom-select" onChange={(e) => {props.getLang(e.target.value); getDropDownValue(e.target.value)}} required>
          <option defaultValue disabled>Native Language</option>
          {languages.map(item =>
    <option key={item.id} value={item.name}>{item.name}</option>)}
    <option value="Other">Other</option>

          </select>
          {dropDownValue === "Other" ? 
          <MDBInput label="Native Language" icon="language" onChange={e => props.getLang(e.target.value)} group type="text" /> 
          : null }
          </> : null}
      </div>
)

}