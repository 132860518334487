import React , {useState, useEffect} from "react";
import Articles from "./Articles.js";
import "./articles.css";
import Navbar from "../../Navbar.js";
import Languages from "./Languages.js";
import {api} from "../../API.js";
import { MDBBtn } from "mdbreact";
import DonateModal from "../DonateModal.js";

export default function Learn(props){

const [languages, showLanguages] = useState(true);
const [languageList, getLanguageList] = useState([]);

   const ListArticles = () =>{
       if(props.articles){
       return props.articles.map((article) =>
        <Articles
        key = {article.id}
        title = {article.title}
        subject = {article.subject}
        url={article.url}
        thumbnail_url = {article.thumbnail_url}
        />)
       }else{
           return null
       }
   } 

useEffect(()=>{
    const LanguageList = async () => {
    const res = await fetch(`${api}languages/learn`);
    const data = await res.json();
    getLanguageList(data);
 }
 LanguageList();
  },[])


    return(
        <>
        <Navbar />
        <div className="d-flex justify-content-center mt-2 w-100">
        <div className="articles">
        <div  className="mb-4 grey-text">
      <MDBBtn style={{marginRight:"-5px"}} className=" font-weight-bold"
      onClick={e => showLanguages(true)} color={languages?"primary" : ""}>Languages</MDBBtn>
        <MDBBtn className=" font-weight-bold"
         onClick={e => showLanguages(false)} color={languages? "" : "primary"}>Articles</MDBBtn>
      </div>
        {languages && props.loaded ? <Languages languages={languageList} /> :  <ListArticles />}
        </div>
        </div>
        <div className="d-flex justify-content-center">
        <div style={{width:"50%", margin:"20px 0px"}}>
        <p>If you enjoy LingYourLanguage, please consider making a donation. 
        The game is not for profit and has no advertising,
         and so we hope you will help LingYourLanguage reach its full potential.
         <br/> 
         <strong>Thank you!</strong>
         </p>
         <DonateModal modal={false} />
         </div>
         </div>
</>
    )
}