import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon } from "mdbreact";
import { Link } from 'react-router-dom';

const Footer = () => {
 
  return (
    <MDBFooter color="blue" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="4">
            <h5 className="title">Contribute</h5>
            <p>
           Don’t see your language here? Want to be a part of LingYourLanguage?
							Contribute to our collection!
							<br />Fill out the
							<a href="https://goo.gl/forms/2urzkZ88UhYwgqWI2"> <strong>form</strong> </a>
                             and represent your language!
            </p>
            <p style={{lineHeight: 1.3 + 'em', fontSize: 0.8 + 'em'}}>
							<b>Note:</b> Hear a sample that shouldn’t be in the game?
							Have a question or comment for us? Send us an email at
							<i> lingyourlanguage@gmail.com </i> to let us know.
						</p>
          </MDBCol>
          <MDBCol md="4" className="text-center">
            <h5 className="title">Check out</h5>
            <ul>
              <li className="list-unstyled">
                <Link to="/game">Play</Link>
              </li>
              <li className="list-unstyled">
              <Link to="/learn">Articles</Link>
              </li>
              <li className="list-unstyled">
              <Link to="/donate">Donate</Link>
              </li>
              <li className="list-unstyled">
                <a target="_blank"  rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfDnELnN3CdmvocKrkH8KHeeCCNYWIwN0r7KG6wYaDNA6Xbgw/viewform">
                    Contribute</a>
              </li>
              <li className="list-unstyled">
              <Link to="/highscores">Highscores</Link>
              </li>
            </ul>
          </MDBCol>
          <MDBCol md="4" className="text-center">
            <h5 className="title">Social media</h5>
            <ul>
              <li className="list-unstyled">
                <a target="_blank"  rel="noopener noreferrer" href='https://twitter.com/LingYourLang'><MDBIcon fab icon="twitter" /></a>
              </li>
              <li className="list-unstyled">
                <a target="_blank"  rel="noopener noreferrer" href='https://www.facebook.com/lingyourlanguage'><MDBIcon fab icon="facebook-f" /></a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: LingYourLanguage
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;