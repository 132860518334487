import React, { useContext, useEffect, useState} from "react";
import { GameContext } from "./Context.js";
import { MDBAnimation, MDBBtn, MDBIcon} from "mdbreact";
import "./game.css";
import { Link } from "react-router-dom";
import DonateModal from "../DonateModal.js";
import Spinner from "../../Spinner.js";

export default function GameOver(){
    
    const game = useContext(GameContext);
    const [loading,isLoading]= useState(true);

    const playAgain =(e) =>{
        window.location.reload();
        return false;
    }

    useEffect(()=>{
        setTimeout(function(){ isLoading(false) }, 3000);
    },[])

    let arrayofPlayers = []

    arrayofPlayers.push({name:game.name1,score:game.score1},{name:game.name2,score:game.score2})
    if(game.name3 !== null){
        arrayofPlayers.push({name:game.name3,score:game.score3})
    }
    if(game.name4 !== null){
        arrayofPlayers.push({name:game.name4,score:game.score4})
    }

    let newArr = arrayofPlayers.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));

    let players = newArr.map((player,index)=>{  
    return  <h3 className={index === 0 ? 'font-weight-bold': ''} style={{marginLeft:  index ===0 ? '-38px': ''}}>
        {index === 0? <MDBIcon className="blue-text" icon="trophy" /> : ""} {player.name} : {player.score}</h3>
});

function moveItem(from, to) {
    // remove `from` item and store it
    let f = arrayofPlayers.splice(from, 1)[0];
    // insert stored item into position `to`
    arrayofPlayers.splice(to, 0, f);
    return arrayofPlayers.map((player,index)=>{  
        return  <h3 className={index === 0 ? 'font-weight-bold': ''} style={{marginLeft:  index ===0 ? '-38px': ''}}>
            {index === 0? <MDBIcon className="blue-text" icon="trophy" /> : ""} {player.name} : {player.score}</h3>
    });
  }
  
  let index = arrayofPlayers.findIndex(x => x.name === game.deadPlayer);
  

  

    return(
<>{arrayofPlayers.length === 0 ?  <Spinner /> :<>
        <MDBAnimation type="zoomIn">
        <div style={{marginTop:"10%"}}>
        <h1 className="font-weight-bold">GAME OVER</h1>
        <div className="d-flex justify-content-center flex-column w-100">
        <div className="align-self-center text-left">
        {game.gameType==='score' ? players : moveItem(index, arrayofPlayers.length)}
    </div>
    </div>
            <MDBBtn color="primary" onClick={playAgain}>Play Again</MDBBtn>
            <Link to="/highscores"><MDBBtn color="primary" >HighScores</MDBBtn></Link>
        </div> 
        </MDBAnimation>
<DonateModal modal={true}/>
</>
}
</>
    )
}