import React, {useState, useEffect, useContext} from "react";
import { generalSearch} from "./Search.js";
import {getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { AppContext } from "../../AppContext.js";


export default function Highscores(){


    const[datas, getDatas] = useState();
    const [loaded, isLoaded] = useState(false);
    
    const tableContext = useContext(AppContext);
    const table = 'highscores';
  
    useEffect(() =>{
      getTable(table,getDatas, isLoaded);
      },[tableContext.tableUpdated]);
  
    return(
      <>
      <h5 className="mt-5">Highscores <MDBIcon icon="trophy" className="light-blue-text"/></h5>  
      <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
    <MDBTableHead>
    <tr>
            <th><input className="form-control" id="myInput6" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
            <th><input className="form-control" id="myInput7" onChange={e => generalSearch(1,e.target.id)}  placeholder="search acc id" /></th>
            <th><input className="form-control" id="myInput10" onChange={e => generalSearch(2,e.target.id)}  placeholder="search name" /></th>
            <th><input className="form-control" id="myInput8" onChange={e => generalSearch(3,e.target.id)}  placeholder="search difficulty" /></th>
            <th><input className="form-control" id="myInput11" onChange={e => generalSearch(4,e.target.id)}  placeholder="search score" /></th>
            <th><input className="form-control" id="myInput9" onChange={e => generalSearch(5, e.target.id)}  placeholder="search created" /></th>
            <th><input className="form-control" id="myInput12" onChange={e => generalSearch(6,e.target.id)}  placeholder="search updated" /></th>
          </tr>
      <tr style={{background:"#33b5e5", color:"white"}}>
        <th>Id</th>
        <th>Acc Id</th>
        <th>Name</th>
        <th>Difficulty</th>
        <th>Score</th>
        <th>Created</th> 
        <th>Updated</th>
      </tr>
    </MDBTableHead>
    <MDBTableBody>
    {loaded ? 
    datas.map(data =>
    <tr key={data.id}>
    <td>{data.id}</td>
    <td>{data.account_id}</td>
    <td>{data.name}</td>
    <td>{data.difficulty}</td>
    <td>{data.score}</td>
    <td>{data.createdAt}</td>
    <td>{data.updatedAt}</td>
  </tr>
    ) 
    : null}
    </MDBTableBody>
  </MDBTable>
      </>
      
  )
  }