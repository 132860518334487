import React, {useState} from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBBtn, MDBIcon, MDBAnimation,MDBModal, MDBModalBody} from "mdbreact";
import {getCookie} from "../../API.js";
import "./home.css";
import HomeNav from "./HomeNav.js";

export default function Home(){

    const size={
    fontSize: 18,
    marginLeft: 5
    }

    const[modal,showModal] = useState(false);
    let token = getCookie("token");

    return(
        <>
                 {token === '' ?   <HomeNav /> : "" }
        <MDBContainer id="home-container">
            <MDBRow className="mb-2">
            <MDBAnimation type="zoomIn">
            <h1 className="logo text-center">
			<span style={{fontSize: 1.1 + 'em'}}>
			  <b>ling</b>your
			</span>
				<br/><b>language</b>
			</h1>
            </MDBAnimation>
            </MDBRow>
            <MDBRow>
            <MDBAnimation type="bounceIn" delay="0.1s">
            <Link to ="/game"><MDBBtn id="home-btn" color="primary">PLAY <MDBIcon icon="gamepad" style={size} /></MDBBtn></Link>
            </MDBAnimation>
            </MDBRow>
            <MDBRow>
            <MDBAnimation type="bounceIn" delay="0.3s">
            <Link to="/learn"><MDBBtn id="home-btn" color="primary">LEARN <MDBIcon icon="graduation-cap" style={size} /></MDBBtn></Link>
            </MDBAnimation>
            </MDBRow>
            <MDBRow>
            <MDBAnimation type="bounceIn" delay="0.2s">
            <Link to="/about"><MDBBtn id="home-btn" color="primary">ABOUT <MDBIcon icon="info-circle" style={size} /></MDBBtn></Link>
            </MDBAnimation>
            </MDBRow>
            <MDBRow>
            <MDBAnimation type="bounceIn" delay="0.2s">
            <Link to="/highscores"><MDBBtn id="home-btn" color="primary">SCORES<MDBIcon icon="trophy" style={size} /></MDBBtn></Link>
            </MDBAnimation>
            </MDBRow>
            <MDBRow>
            <MDBAnimation type="bounceIn" delay="0.3s">
            <MDBBtn id="home-btn" onClick={e=>showModal(!modal)} color="primary">Donate <MDBIcon icon="hand-holding-usd" style={size} /></MDBBtn>
            </MDBAnimation>
            </MDBRow>
            <MDBRow id="social">
            <a target="_blank" className="blue-text"  rel="noopener noreferrer" href="https://twitter.com/LingYourLang">
                <MDBIcon fab icon="twitter" style={size} /></a> 
                <a target="_blank" className="blue-text"  rel="noopener noreferrer" href="https://www.facebook.com/lingyourlanguage">
            <MDBIcon fab icon="facebook" style={size} />
            </a>
            </MDBRow>
            <MDBContainer>
        <MDBModal isOpen={modal} toggle={e=> showModal(!modal)} side position="bottom-right">
        <MDBIcon far icon="times-circle" className="grey-text pt-2 text-right mr-2" style={{cursor:"pointer"}}
        onClick={e=> showModal(!modal)} />
          <MDBModalBody>
          <h5 className="light-blue-text">
            <strong>
                Donate
                </strong>
                </h5>
       <p>If you enjoy LingYourLanguage, please consider making a donation. 
        The game is not for profit and has no advertising,
         and so we hope you will help LingYourLanguage reach its full potential.
         <br/> 
         <strong>Thank you!</strong>
         </p>
         <div className="d-flex flex-row justify-content-center">
             <div className="d-flex flex-row justify-content-center">

         <a target="_blank" rel="noopener noreferrer"  href="https://www.paypal.com/paypalme/lingyourlang">
            <MDBBtn color="primary" className="mr-1" style={{width:30, height: 30, borderRadius: "50%", padding: 0, margin: "auto"}}>
             <MDBIcon fab icon="paypal" />
            </MDBBtn>
            </a>

          <Link to="/donate">
            <MDBBtn color="primary" style={{width:30, height: 30, borderRadius: "50%", padding: 0, margin: "auto"}}>
            <MDBIcon fab icon="stripe-s" />
            </MDBBtn>
            </Link>
            </div>
            </div>
          </MDBModalBody>
        </MDBModal>
            </MDBContainer>
    </MDBContainer>
</>
    )
}