import React, {useEffect, useState, useContext} from "react";
import { MDBContainer, MDBRow, MDBCard, MDBTable, MDBTableBody, MDBTableHead, MDBAnimation, MDBProgress } from 'mdbreact';
import Navbar from "../../Navbar.js";
import { Redirect, Link } from "react-router-dom";
import { AppContext } from "../../AppContext.js";
import { getCookie, getImg } from "../../API.js";
import Spinner from "../../Spinner.js";
import axios from 'axios';

export default function Account(){

  let api = process.env.REACT_APP_API_URL;

  const account = useContext(AppContext);
  const[loading, isLoading] = useState(true);
  const[usersCountry,getUsersCountry] =useState();
  const[usersHighScores, getUsersHighScores]=useState();
  const[usersNativeLang,getUsersNativeLang]=useState();


  const max = () =>{
    if(usersHighScores !== undefined){
      return Math.max.apply(Math, usersHighScores);
    }else{
      return '0'
    }
  } 
    
const logOut = () =>{
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      account.getRerender(!account.rerender);
      account.getAccount();
    }
     
let name = getCookie('username');
let role = getCookie('role');

useEffect(() =>{
  getUserInfo();
  if(name !== undefined){
   setTimeout(() => getUserInfo(), 200)
  }
  setTimeout(() => isLoading(false), 1000)
},[])

 let username = getCookie('username');

 function getUserInfo(){
 axios.get(`${api}accounts/${name}`, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
  })
.then(response => { 
    getUsersCountry(response.data.country);
    getUsersNativeLang(response.data.nativeLanguage);
    getUsersHighScores(response.data.highscores);
  })
  .catch(error => {

  });
  }
 


const[score, getScore]= useState(0);
const[nextTitle, getNextTitle]= useState(0);
const[currentTitle, getCurrentTitle] = useState("");
const[nextLvl, getNextLvl]= useState();
const[image,getImage] = useState();

async function profilePic() {
  let data = await getImg(username);
  getImage(data);
}

useEffect(() =>{

  profilePic(); 

  if(loading){
   return 
  }else if(usersHighScores !== undefined){   
  let userScores = parseInt(usersHighScores[0]) + parseInt(usersHighScores[1]) + parseInt(usersHighScores[2]) + parseInt(usersHighScores[3]);
  getScore(userScores);

  switch(true) {
    case(userScores < 500) :
    getCurrentTitle('Language enthusiast');
     getNextTitle('Monolingual');
     getNextLvl(500);
      break;
    case(userScores < 1250):
      getCurrentTitle('Monolingual');
      getNextTitle('Bilingual');
      getNextLvl(1250);
      break;
    case(userScores < 2250):
      getCurrentTitle('Bilingual');
      getNextTitle('Trilingual');
      getNextLvl(2250);
      break;
    case(userScores < 3500):
    getCurrentTitle('Trilingual');
      getNextTitle('Multilingual');
      getNextLvl(3500);
      break;
    case(userScores < 5000):
      getCurrentTitle('Multilingual');
        getNextTitle('Plurilingual');
        getNextLvl(5000);
        break;
    case(userScores < 7000):
        getCurrentTitle('Plurilingual');
          getNextTitle('Polyglot');
          getNextLvl(7000);
          break;
    case(userScores < 10000):
          getCurrentTitle('Polyglot');
            getNextTitle('Hyperpolyglot');
            getNextLvl(10000);
            break;
    case(userScores < 15000):
            getCurrentTitle('Hyperpolyglot');
              getNextTitle('Omniglot')
              getNextLvl(15000);
              break;
    case(userScores >= 15000 ):
      getCurrentTitle('Omniglot');
      getNextTitle('Omniglot');
      getNextLvl(15000);

      break;
    default:
      return null
  }

  }

  return () => getImage();
},[loading])



    return(
        <>
{account.account !== "" ? 
<>
{loading ? <Spinner /> :
        <>
        <Navbar />
        <MDBAnimation type="fadeIn">
        <MDBContainer className="mb-3">
  <MDBRow className="w-100 d-flex justify-content-center ml-0 mr-0">
    <MDBCard id="card" className="mt-5 mb-1 d-flex flex-column pt-2" style={{overflowX:"auto"}}>
      <div className= { role === 'ADMIN' ? "mt-2 mr-3 d-flex justify-content-between" :  "mt-2 mr-3 d-flex justify-content-end"} >
     { role === 'ADMIN' ?  <Link to="/admin"><i style={{cursor:"pointer"}} className="fas fa-cog fa-2x blue-text ml-3"></i></Link> : null}
        <i style={{cursor:"pointer"}} onClick={e => logOut()} className="fas fa-sign-out-alt fa-2x blue-text"></i>
        </div>
        <div className="d-flex flex-sm-row flex-column-reverse justify-content-around">
        <div className="mt-5 text-left ml-sm-3  ml-5 mr-2">
            <h6><strong className="blue-text">Name: </strong> {username}</h6>
            <h6><strong className="blue-text">Country: </strong> {usersCountry}</h6>
            <h6><strong className="blue-text">Native language: </strong> {usersNativeLang}</h6>
            <h6><strong className="blue-text">Current title: </strong> {currentTitle}</h6>
            <h6><strong className="blue-text">High Score: </strong> {max()}</h6>
        </div>
        <div className="mt-5">
            <img alt="" id="img" src={image} width="100" height="100" />
        </div>
        </div>
        <div style={{width:"70%", marginLeft:"15%"}} className="mt-3 mb-2">
        <MDBProgress animated value={score} max={nextLvl} height="20px" />
        <div className="d-flex justify-content-around flex-row grey-text">
        <p>{nextTitle}</p>
        <p>{score}/{nextLvl}</p>
        </div>
        </div>
        <MDBTable className="mt-3">
      <MDBTableHead color="primary-color" textWhite>
        <tr>
          <th>Easy</th>
          <th>Regular</th>
          <th>Hard</th>
          <th>Omniglot</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
     <tr>
          <td>{usersHighScores !== undefined ? usersHighScores[0] : "0" }</td>
          <td>{usersHighScores !== undefined? usersHighScores[1] : "0" }</td>
          <td>{usersHighScores !== undefined ? usersHighScores[2] : "0" }</td>
          <td>{usersHighScores !== undefined ? usersHighScores[3] : "0" }</td>
        </tr>
        </MDBTableBody>
        </MDBTable>
    </MDBCard>
    </MDBRow>
    </MDBContainer>
    </MDBAnimation>
        </> } </> :  <Redirect to='/signin'  />}
        </>
    )
}