import React from 'react';
import { BrowserRouter as Switch, Route} from "react-router-dom";
import {getArticles, getCookie, signUpSucces} from "./API.js";
import {useState, useEffect} from "react";
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import Game from"./components/Play/Game.js";
import Stripe from"./components/Play/Stripe.js";
import Learn from "./components/Learn/Learn.js";
import Article from "./components/Learn/Article.js";
import Spinner from "./Spinner.js";
import AdminPanel from "./components/Admin/AdminPanel.js";
import SignUp from "./components/Login/Signup.js";
import SignIn from "./components/Login/Signin.js";
import Account from "./components/Login/Accounts.js";
import Password from "./components/Login/Password.js";
import ChangePassword from "./components/Login/ChangePassword.js";
import HighScores from "./components/Highscores/HighScores.js";
import './App.css';
import './mediaq.css';
import {AppContext} from "./AppContext.js";
import CookieConsent from "react-cookie-consent";

function App() {

  const [articles, getArticle] = useState();
  const [loader, isLoading] = useState(true);
  const [account, getAccount] = useState();
  const [rerender, getRerender] = useState(false);
  const [newUser, isCreated] = useState(false);


  var token = getCookie("token");
  //Spinner on load


  useEffect(() => {
    setTimeout(() => isLoading(false), 1000)
    getAccount(token);
  }, [token]);

  useEffect(() =>{
  if(signUpSucces === "OK"){
    isCreated(true);
  }else{
    isCreated(false)
  }
},[signUpSucces]);


  useEffect(()=>{
  getAccount(token);
},[rerender])


  useEffect(()=>{
  async function articles() {
    //this part is fetching data from the API, and it's passing it to the variables
    const article = await getArticles();
    //console.log(article.json())
    getArticle(article);
    getAccount(getCookie("token"));
  }
  articles();
},[])

const loaded = true;

  return (
    <div className="App">
      {loader ? <Spinner /> :
    <Switch>

          <Route path='/' exact component={Home}/>
          <Route path='/about' component={About}/>

          <Route
          path="/signup"
          render={(props) => 
          <AppContext.Provider value={{newUser, rerender,getRerender}}>
          <SignUp/>
          </AppContext.Provider>} 
          />

          <Route
          path="/signin"
          render={(props) => 
          <AppContext.Provider value={{account,getAccount, rerender,getRerender, isCreated}}>
          <SignIn/>
          </AppContext.Provider>} 
          />

          <Route
          path="/account"
          render={(props) => 
          <AppContext.Provider value={{account,getAccount, rerender,getRerender}}>
          <Account/>
          </AppContext.Provider>} 
          />

          <Route
          path="/learn"
          render={(props) => <Learn articles={articles} loaded={loaded} />} 
          />

         <Route
          path="/game"
          render={(props) => <Game  />} 
          />
          
         <Route
          path="/donate"
          render={(props) => <Stripe />} 
          />

         <Route
          path="/admin"
          render={(props) => <AdminPanel getRerender={getRerender} rerender={rerender} />} 
          /> 
          <Route path='/highscores' component={HighScores}/>
          <Route path='/password' component={Password}/>
          <Route path='/changepassword' component={ChangePassword}/>
          <Route path="/article/:id">
          <Article />
          </Route>
        </Switch>}
{ getCookie('CookieConsent') ?  null :
  <>
        <CookieConsent
          debug={true}
          style={{ background: "#33b5e5" }}
          buttonStyle={{ color:"#33b5e5", fontSize: "13px", background:"white",marginTop:"0px" }}
          expires={150}
        >
          <p className="text-left">This website uses cookies to ensure you get the best experience on our website.</p>
        </CookieConsent>
        </>}
    </div>
  );
}

export default App;
