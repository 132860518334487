import React, {useState,useContext, useEffect} from "react";
import { MDBCard, MDBCardTitle, MDBBtn,  MDBContainer, MDBCol, MDBRow, MDBIcon, MDBInput } from "mdbreact";
import "./game.css";
import { GameContext } from "./Context.js";

export default function PlayerNames(props){
  
    const game = useContext(GameContext);
    const[player1, getPlayer1] = useState(null);
    const[player2, getPlayer2] = useState(null);
    const[player3, getPlayer3] = useState(null);
    const[player4, getPlayer4] = useState(null);
    const[playerLives, setLives] = useState();
    const[scoreMode, setScoreMode] = useState(true);
    const[gameMode, isChosen] = useState(false);

    const setPlayers = () =>{
      props.getPlayers(true);
      game.getPlayer1({name1: player1, lives1:playerLives, score1: 0});
      game.getPlayer2({name2: player2, lives2:playerLives, score2: 0});
      game.getPlayer3({name3: player3, lives3:playerLives, score3: 0});
      game.getPlayer4({name4: player4, lives4:playerLives, score4: 0});
    }


    function setScore(score){
      game.setMultiScore(score);
      setLives(-2);
      isChosen(true)
    }

//Number of lives, and game mode chosen
function getLives(lives){
setLives(lives);
game.setMultiScore(0)
isChosen(true);
}

//Start the game
function startGame(){
  if(gameMode){
  setPlayers();
}else{
  return null
}
}

//If the players didn't set the names, set the default names 
function players(number){
  game.getNumberOfPlayers(number)
  if(number === 2){
    getPlayer1('Player 1');
    getPlayer2('Player 2');
  }else if(number === 3){
    getPlayer1('Player 1');
    getPlayer2('Player 2');
    getPlayer3('Player 3');
  }else{
    getPlayer1('Player 1');
    getPlayer2('Player 2');
    getPlayer3('Player 3');
    getPlayer4('Player 4');
  }
}

//default number of players for the multi-player mode
useEffect(()=>{
  players(2)
},[])

    return(
        <>
    {!props.hasPlayers ? <MDBContainer className="d-flex justify-content-center mt-5">
  <MDBCard className="card-body w-75" style={{ marginBottom: "2%"}}>
<MDBCardTitle className="blue-text">Multiplayer Mode <MDBIcon icon="users" /></MDBCardTitle>
<div id="radiobtns" className="d-flex flex-row justify-content-center mb-2 mt-2">
        <MDBInput
          onClick={e => players(2)}
          checked={game.numberOfPlayers === 2 ? true : false}
          label='2'
          type='radio'
          id='radio1'
          containerClass='mr-5 ml-5'
        />
        <MDBInput
          onClick={e => players(3)}
          checked={game.numberOfPlayers === 3 ? true : false}
          label='3'
          type='radio'
          id='radio2'
          containerClass='mr-5'
        />
        <MDBInput
          onClick={e => players(4)}
          checked={game.numberOfPlayers === 4 ? true : false}
          label='4'
          type='radio'
          id='radio3'
          containerClass='mr-5'
        />
      </div>
<div className="form-group d-flex justify-content-center">
        <div className="players-container text-center">
<div> 
{game.numberOfPlayers === 2 ?
<>
        <input type="text" onChange={ e => getPlayer1(e.target.value)} placeholder="Player 1" className="form-control form-control-sm mt-2" required />    
        <input type="text" onChange={ e => getPlayer2(e.target.value)} placeholder="Player 2" className="form-control form-control-sm mt-2"  required />
</>
:null }  
{game.numberOfPlayers === 3 ? 
<>
        <input type="text" onChange={ e => getPlayer1(e.target.value)} placeholder="Player 1" className="form-control form-control-sm mt-2" required />    
        <input type="text" onChange={ e => getPlayer2(e.target.value)} placeholder="Player 2" className="form-control form-control-sm mt-2"  required />    
        <input type="text" onChange={ e => getPlayer3(e.target.value)} placeholder="Player 3" className="form-control form-control-sm mt-2"  required /> 
</> : null}
{game.numberOfPlayers === 4 ?
<>
        <input type="text" onChange={ e => getPlayer1(e.target.value)} placeholder="Player 1" className="form-control form-control-sm mt-2" required />    
        <input type="text" onChange={ e => getPlayer2(e.target.value)} placeholder="Player 2" className="form-control form-control-sm mt-2"  required />    
        <input type="text" onChange={ e => getPlayer3(e.target.value)} placeholder="Player 3" className="form-control form-control-sm mt-2"  required /> 
        <input type="text" onChange={ e => getPlayer4(e.target.value)} placeholder="Player 4" className="form-control form-control-sm mt-2"  required />
 </>
 : null}       
        </div> 
      
</div>
      </div>
        <MDBContainer className="d-flex justify-content-center flex-column w-75">

        <MDBRow center className="w-75 justify-content-center align-self-center text-center blue-text mt-3 mb-4 d-flex flex-row">

        <MDBCol center="true" className="d-flex justify-content-center flex-column">
        <MDBBtn id="player-btn" onClick={e => setScoreMode(true)} color={scoreMode ? "primary" : "" }>Score</MDBBtn>
        </MDBCol>

        <MDBCol center="true" className="d-flex justify-content-center flex-column">
        <MDBBtn id="player-btn"  onClick={e => setScoreMode(false)} color={scoreMode ? "" : "primary" }>Lives</MDBBtn>
        </MDBCol>
        
         </MDBRow>

         <MDBRow id="gamemode-container" className="d-flex justify-content-center align-self-center flex-column">
        { scoreMode ? 
        <>
            <MDBBtn outline = {game.multiScore === 100 ? false : true}  color={game.multiScore === 100 ? "primary" : "info"} onClick={(e) => {setScore(100);game.getGameType('score')}} >Quick</MDBBtn>
            <MDBBtn outline = {game.multiScore === 200 ? false : true}  color={game.multiScore === 200 ? "primary" : "info"} onClick={(e) => {setScore(200);game.getGameType('score')}}>Normal</MDBBtn>
            <MDBBtn outline = {game.multiScore === 300 ? false : true} color={game.multiScore === 300 ? "primary" : "info"} onClick={(e) => {setScore(300);game.getGameType('score')}} >Long</MDBBtn>
        </>
:
        <>
            <MDBBtn  outline = {playerLives === 1 ? false : true}  color={playerLives === 1 ? "primary" : "info"}  onClick={(e) => {getLives(1);game.getGameType('lives')}}>Sudden death</MDBBtn>
            <MDBBtn  outline = {playerLives === 3 ? false : true} color={playerLives === 3 ? "primary" : "info"}  onClick={(e) => {getLives(3);game.getGameType('lives')}}>Normal (3 lives)</MDBBtn>
            <MDBBtn  outline = {playerLives === 10 ? false : true} color={playerLives === 10 ? "primary" : "info"}  onClick={(e) => {getLives(10);game.getGameType('lives')}}>Long (10 lives)</MDBBtn>
        </>
}
      </MDBRow>

    </MDBContainer>
    <MDBBtn className="mt-5" color="primary" onClick={startGame}>Continue</MDBBtn>
  </MDBCard>
</MDBContainer> 
: null}
</>
    )
}