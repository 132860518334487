import React from "react";
import { MDBBtn } from "mdbreact";
import { Link } from 'react-router-dom';

export default function HomeNav() {

  return (
    <>
      <ul style={{listStyle:"none"}} className="d-flex flex-row justify-content-end">
        <li>
        <Link to="/signin">
            <MDBBtn  id="nav-btn" className="white-text font-weight-bold mb-2" style={{fontSize: 13,borderRadius: 25,
              padding:"8px 25px", margin:"10px 10px 0px 0px"}} color="primary">Log In</MDBBtn>
            </Link>
        </li>
        <li>
        <Link to="/signup">
            <MDBBtn  id="nav-btn" className="blue-font mb-2
             font-weight-bold" style={{fontSize: 13,borderRadius: 25,
              margin:"10px 30px 0px 0px", padding:"8px 25px"}} color="white">Sign Up</MDBBtn>
            </Link>
        </li>

      </ul>
    </>
    );
}