import React, { useState } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';

export default function DonateModal(props) {

const[modal, getModal] = useState(props.modal);

  return (
    <MDBContainer className="text-center">
      <MDBBtn color="primary" className="mb-3" onClick={e=> getModal(!modal)}>Donate</MDBBtn>
      <MDBModal isOpen={modal} toggle={e=> getModal(!modal)} side position="top-right">
        <MDBModalBody className="d-flex flex-column" style={{marginTop:"-10px"}}>
        <MDBIcon far icon="times-circle" className="grey-text pt-2 text-right" style={{cursor:"pointer"}}
        onClick={e=> getModal(!modal)} />
            <h3 className="light-blue-text">
            <strong>
                Donate
                </strong>
                </h3>
       <p>If you enjoy LingYourLanguage, please consider making a donation. 
        The game is not for profit and has no advertising,
         and so we hope you will help LingYourLanguage reach its full potential.
         <br/> 
         <strong>Thank you!</strong>
         </p>
         <div className="d-flex flex-row justify-content-center">
             <div className="d-flex flex-row justify-content-center">

         <a target="_blank" rel="noopener noreferrer"  href="https://www.paypal.com/paypalme/lingyourlang">
            <MDBBtn color="primary" className="mr-1" style={{width:40, height: 40, borderRadius: "50%", padding: 0, margin: "auto"}}>
             <MDBIcon fab icon="paypal" />
            </MDBBtn>
            </a>

          <Link to="/donate">
            <MDBBtn color="primary" style={{width:40, height: 40, borderRadius: "50%", padding: 0, margin: "auto"}}>
            <MDBIcon fab icon="stripe-s" />
            </MDBBtn>
            </Link>
            </div>
            </div>

        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
    );
  }