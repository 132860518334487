import React from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow, MDBIcon} from 'mdbreact';

export default function GameMode(props){
 

    const cards={
     margin: 5,
     height: 180,
     width: 200, 
     cursor: "pointer"
    }

    const singlePlayer = () =>{
        props.getGameMode({difficulty: true});
        props.getSinglePlayer(true);
    }

    const multiPlayer = () =>{
        props.getGameMode({difficulty: true});
        props.getSinglePlayer(false);
    }

    return(
        <div> 
            <h1>Game Mode</h1>
            <h5 className="mb-4 mt-2">Choose a game mode</h5>
       <MDBContainer>
           <MDBRow className="d-flex justify-content-center">
  <MDBCard  style={cards} onClick={ e => singlePlayer()}>
  <MDBCardBody>
        <h5 className="blue-text" >Singleplayer</h5>
        <p className="grey-text">Play alone</p>
        <MDBIcon className="blue-text" icon="user-alt" style={{fontSize: 42}} />
    </MDBCardBody>
  </MDBCard>

  <MDBCard style={cards} onClick={ e => multiPlayer()}>
    <MDBCardBody>
        <h5 className="blue-text" >Multiplayer</h5>
        <p className="grey-text">Play with a friend</p>
        <MDBIcon className="blue-text" icon="user-friends" size="3x"/>
    </MDBCardBody>
  </MDBCard>
  </MDBRow>
</MDBContainer>
        </div>

    )
}