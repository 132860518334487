import axios from 'axios';

export var api = process.env.REACT_APP_API_URL;

export let token  = getCookie('token');


//This is fetching languages from the API
export const getLang = async () => {
    const res = await fetch(`${api}languages/learn`);
    const data = await res.json();
    return data
  }

//This is fetching articles from the API
  export const getArticles = async () => {
    const res = await fetch(`${api}articles`);
    const data = await res.json();
    //console.log(data);
    return await data;
  };

//Fetch profile image
export const getImg = async (name) => {
  const res = await fetch(`${api}avatars/${name}`);
  const data = await res.blob();
  let image = URL.createObjectURL(data)
  return image;
}

  /////////////////////////////////
export const deleteRow = (table,id, getErr) => {
   axios({
    method: "DELETE",
      url:`${api}admin/dashboard/${table}/${id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization':  `bearerToken ${token}`
      }
})
.then((response) => {
  getErr(false)
})
.catch((error) => {
  getErr(true);
});
}

/////////////////////////////////

export const getTable = (table, getDatas,isLoaded)=>{
 axios({
  method: "GET",
  url: `${api}admin/dashboard/${table}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization':  `bearerToken ${token}`
}
}).then((response) => {
  getDatas(response.data);
 isLoaded(true);
})
.catch((error) => {
  if(error.response === undefined){
  }else{
  if(error.response.data.message.includes("Token couldn't be validated")){
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
}
});
}


  export const addData = (table, data, getErr) => {
    let ins = axios.create();
    ins.interceptors.request.use((config) => {
      return config;
    })
    ins({
      method: "POST",
      url: `${api}admin/dashboard/${table}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization':  `bearerToken ${token}`
      },
      data: data
    }).then((response) => {
       getErr(false);
    })
    .catch((error) => {
      getErr(true);
    });
  }


  
  export const changeData = (table, data, getErr,id) => {
    //  let ins = axios.put();
    //  ins.interceptors.request.use((config) => {
    //    return config;
    //  })
    axios({
      method: "PUT",
      url: `${api}admin/dashboard/${table}/${id}`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization':  `bearerToken ${token}`
      }
    }).then((response) => {
       getErr(false);
    })
    .catch((error) => {
      getErr(true);
     // console.log(error.response);
     // console.log(data);
    });
  }

  
  //COOKIES

function setToken(token) {
    if(token !== undefined){
    document.cookie = "token" + "=" + token;
    }else{
      return null
    }
  }
  
export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


//export let username = getCookie('username');


 

export let message;
export let role;

//LOG IN
export default async function LogIn(email,pass){

message=undefined;
const url  = `${api}login`;

await axios.post(url, {
  email: email,
  password: pass
}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
})
.then(response => {
  document.cookie = "username" + "=" + response.data.username; 
  document.cookie = "role" + "=" + response.data.role;
  setToken(response.data.token);
  role = response.data.role;
  message = undefined;
})
.catch(error => {
   message = "Login failed, wrong user credentials!";
  });
token = getCookie('token');
}



//SIGN UP

export let signUpSucces;
export var newUser;

export function createAccount(country, email, language, password, username, spokenlangs){
  axios.post(`${api}signup`, {
    country: country,
    email: email,
    native_language : language,
    password: password,
    username: username,
    spoken_languages: spokenlangs
  }, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
  })
  .then(response => { 
    signUpSucces = true;
    newUser=true;
    setTimeout(function(){ newUser=false }, 3000);
  })
  .catch(error => {
      signUpSucces = false;
      newUser=false;
  });
  }

//Passwords
export let passEmailSent;
export async function forgottenPassword(email){
await axios.post(`${api}accounts/new/password`, {
  email: email,
}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
})
.then(response => {
passEmailSent= true;
})
.catch(error => {
passEmailSent=false;
});
}



export async function changePassword(newPass,hash){
 
  await axios.post(`${api}accounts/change/password/${hash}`, {
    new_password: newPass,
  }, {
          headers: {
            'Content-Type':'application/json'
          }
  })
  .then(response => {
  })
  .catch(error => {
  });
  }
  
