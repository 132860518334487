import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {addData} from "../../../API.js";
import Alert from "./Alert.js";
import "../admin.css";
import { AppContext } from "../../../AppContext.js";

export default function LanguagePropsModal(props){

  const [error, getErr] = useState();
  const [data ,setData]= useState({  
    id: "",
    autonym: "",
    transliteration: "",
    ipa: "",
    official_in: "",
    recognized_minority_in: "",
    significant_minority_in: "",
    total_speakers: "",
    native_speakers: "",
    l2_speakers: "",
    spoken_in: ""});
  
  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const table = 'language_props';
  const tableContext = useContext(AppContext);


  const refreshTable = () =>{
    addData(table,data,getErr);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
    <>
      <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Add language prop <MDBIcon className="light-blue-text" icon="language" /></h3>
        </MDBModalHeader>
        <MDBModalBody>  
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}>
      <MDBInput onChange={handleChange}  name="id" label="Id (number)" />
      <MDBInput onChange={handleChange}  name="autonym" label="Autonym"/>
      <MDBInput onChange={handleChange}  name="transliteration" label="Transliteration" />
      <MDBInput onChange={handleChange}  name="ipa" label="Ipa"/>
      <MDBInput onChange={handleChange}  name="official_in" label="Official_In" />
      <MDBInput onChange={handleChange}  name="recognized_minority_in" label="recognized_minority_in"/>
      <MDBInput onChange={handleChange}  name="significant_minority_in" label="significant_minority_in" />
      <MDBInput onChange={handleChange}  name="total_speakers" label="total_speakers"/>
      <MDBInput onChange={handleChange}  name="native_speakers" label="native_speakers" />
      <MDBInput onChange={handleChange}  name="l2_speakers" label="l2_speakers"/>
      <MDBInput onChange={handleChange}  name="spoken_in" label="spoken_in" />
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr}/>
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
        </MDBModal>
    </>
)
}