import React, {useState, useEffect, useContext} from "react";
import { generalSearch} from "./Search.js";
import {getTable} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { AppContext } from "../../AppContext.js";

export default function Audio(props){

  const[datas, getDatas] = useState();
  const [loaded, isLoaded] = useState(false);

  const tableContext = useContext(AppContext);
  const table = 'sounds';

  useEffect(() =>{
    getTable(table,getDatas, isLoaded);
    },[tableContext.tableUpdated]);


    return(
        <>
        <h5 className="mt-5">Audio <MDBIcon className="light-blue-text" icon="volume-up" /></h5>  
        <MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
      <MDBTableHead>
      <tr>
          <th><input className="form-control" id="myInput1" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
          <th><input className="form-control" id="myInput2" onChange={e => generalSearch(1,e.target.id)}  placeholder="search language_id" /></th>
          <th><input className="form-control" id="myInput3" onChange={e => generalSearch(2,e.target.id)}  placeholder="search file_name" /></th>
          <th><input className="form-control" id="myInput4" onChange={e => generalSearch(3, e.target.id)}  placeholder="search source" /></th>
          <th><input className="form-control" id="myInput5" onChange={e => generalSearch(4,e.target.id)}  placeholder="search checksum" /></th>
        </tr>
        <tr style={{background:"#33b5e5", color:"white"}}>
          <th>Id</th>
          <th>Language_id</th>
          <th>File_name</th>
          <th>Source</th>
          <th>Checksum</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      {loaded ? 
      datas.map(data =>
      <tr key={data.id}>
      <td>{data.id}</td>
      <td>{data.language_id}</td>
      <td>{data.file_name}</td>
      <td>{data.source}</td>
      <td>{data.checksum}</td>
    </tr>
      ) 
      : null}
      </MDBTableBody>
    </MDBTable>
        </>
        
    )
}