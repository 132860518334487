import React, { useState, useEffect} from "react";
import ReactMarkdown from 'react-markdown';
import { withRouter } from "react-router-dom";
import Spinner from "../../Spinner.js";
import Navbar from "../../Navbar.js";
import {api} from "../../API.js";
import InfoBox from "./InfoBox.js";
import "./articles.css";

 function Article(props){
    const [text, getText] = useState();
    const [markdown, getMarkdown] = useState();
    const [loader, isLoading] = useState(true);
    const [{footnote,show}, setFootnote] = useState({footnote:"", show: false});

     
    useEffect(() =>{  
      const getArticles = async () => {
      const res = await fetch(`${api}articles`);
      const data = await res.json();
      const { params } = props.match;
      const articleMatch = data.find(article => article.title === params.id);
      getText(articleMatch.url);
    }
    getArticles();

    const markdownToHTML = async () =>{ 
    const getHtml = await fetch(`${text}`);
    const html = await getHtml.text();
    getMarkdown(html);
    }
    markdownToHTML();

    },[props.match, markdown, text]);



    useEffect(() => {
        window.scrollTo(0,0);
        setTimeout(() => isLoading(false), 2000)
        
      }, []);


      useEffect(()=>{
        //get all the footnotes on the bottom and hide them
        [...document.querySelectorAll("a[href*='']")].filter(a => a.parentNode.textContent.includes(`:`) ).forEach(a => {
          a.style.display="none";
          a.parentNode.style.display="none";
        })
      },[loader])

      setTimeout(function(){ allFootnotes(); }, 3000);

      function footnoteSearch(item){
        const getFootnote = (note) =>{
          [...document.querySelectorAll("a")]
         .filter(a => a.textContent.includes(`${note}`) ).forEach(a => {
           if(a.parentNode.textContent.includes(":") && a.textContent.length === note.length){
            setFootnote({footnote:a.parentNode.textContent.substring(1), show: true})
           }
          })
      } 
        item.href="javascript:void(0)";
        item.addEventListener("click", function() {getFootnote(item.textContent)})
      }

      const allFootnotes = () =>{
   [...document.querySelectorAll("a")]
   .filter(a => a.textContent.includes("^"))
   .forEach(a => footnoteSearch(a))
      }
 
    return(
        <>
        {loader ? <Spinner /> :  
        <> 
        <Navbar />
        <InfoBox footnote={footnote} show={show} setFootnote={setFootnote} />
         <div className="d-flex justify-content-center mt-5 w-100">
           <div className="article mt-5">

            <ReactMarkdown  renderers={{ root: React.Fragment }} 
            source={markdown}/>
            </div>
        </div>
        </>
        }
        </>
    )
}
export default withRouter(Article);