import React, { useState, useEffect } from "react";
import { MDBListGroup, MDBListGroupItem, MDBCard, MDBCardTitle, MDBCardText, MDBContainer, MDBRow, MDBAnimation} from "mdbreact";
import {api} from "../../API.js";
import "../../App.css";

export default function Languages(props){
 
const [language, getLanguage] = useState(53);
const [{autonym,classification,name,minority, speakers, official, spokenIn, transliteration}, getDetails] = useState({});

    const LanguageList = props.languages.map(lang =>
        <MDBListGroupItem className="li" onClick={e => getLanguage(lang.id)} key={lang.id} id={lang.id}>
           <h5>{lang.name}</h5>
           </MDBListGroupItem>
        )

useEffect(()=>{
    const languageDetails = async () => {
    const res = await fetch(`${api}languages/data/${language}`);
    const data = await res.json();
    getDetails({
        autonym: data.autonym,
        name: data.language,
        minority: data.minority_in,
        classification: data.classification.join(", "),
        speakers: data.native_speakers,
        official: data.official_in,
        spokenIn: data.spoken_in,
        transliteration: data.transliteration
    })
 }
 languageDetails();
  },[language])


  //search for a language, show the languages that match and hide the languages that don't  
  function searchList() {
    let input, filter, ul, li, lang, i, txtValue;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    ul = document.getElementById("langlist");
    li = ul.querySelectorAll(".li"); 

    for (i = 0; i < li.length; i++) {
        lang = li[i].getElementsByTagName("H5")[0];
        txtValue = lang.textContent || lang.innerText;
       // if (txtValue.toUpperCase().indexOf(filter) > -1) {
        if (txtValue.toUpperCase().startsWith(filter)) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}   


return(
    <MDBAnimation type="slideInRight">
    <MDBContainer  style={{ margin: 0, padding: 0, height: "100%", paddingBottom: 100}}>
        <MDBRow className="languages">

        <MDBCard className="card-body" style={{ width: "22rem", height: 500, marginTop: 0}}>          
  <MDBListGroup id="langlist" style={{ height: 500, overflowY:"scroll", cursor: "pointer"}}>
  <MDBListGroupItem>
  <div className="form-group">
        <input placeholder="Search language" type="text" id="search" onChange={ e => searchList()}
         className="form-control form-control-md text-center" />
      </div>
  </MDBListGroupItem>
        {LanguageList}
        </MDBListGroup>
        </MDBCard>

        <MDBCard className="card-body lang-details" id="scroll" style={{width: "22rem", height: 500, overflowY:"scroll"}}>
<MDBCardTitle className="font-weight-bold">{name}</MDBCardTitle>
    <MDBCardText className="text-left">
        <ul style={{listStyle:"none"}}>
        { autonym  !== undefined  ? <li><b>Autoynm:</b> {autonym}</li> : null}
        { spokenIn !== undefined  ? <li><b>Spoken in:</b> {spokenIn}</li> : null}
        { transliteration  !== undefined  ? <li><b>Transliteration:</b> {transliteration}</li> : null}
        { official  !== undefined ? <li><b>Official language in:</b> {official}</li> : null}
        { minority  !== undefined ? <li><b>Recognized minority language in:</b> {minority}</li> : null}
        { speakers  !== undefined ? <li><b>Native speakers:</b> {speakers}</li> : null}
        { classification  !== undefined ? <li><b>Classification:</b></li>  :null}
        { classification  !== undefined ? <li className="ml-3">    <b>Family:</b> {classification} </li> : null}
        </ul>
    </MDBCardText>
    </MDBCard>

    </MDBRow>
</MDBContainer>
</MDBAnimation>   
)
}