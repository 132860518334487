import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import { addData } from "../../../API";
import Alert from "./Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function GameModal(props){

const [error, getErr] = useState();
const [data ,setData]= useState({
  id: 0,
  answer_min_for_level: 0,
  answer_max_for_level: 0,
  groups_on: "",
  hard_for_level: 0,
  max_points_for_level: 0,
  max_ranks: 0,
  mult_answers: 0,
  mult_rank: 0,
  mult_relation: 0
});

const table = 'levels';
const tableContext = useContext(AppContext);


const handleChange = e => {
  setData({
    ...data,
    [e.target.name]: e.target.value
  });
};

const refreshTable = () =>{
  addData(table,data,getErr)
  tableContext.getTableUpdate(true);
  setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
}

useEffect(()=>{
  if(!props.modal){
    getErr(undefined)
  }else{
    return
  }
},[props.modal])

return(
    <MDBModal isOpen={props.modal} toggle={props.toggle} >
    <MDBModalHeader toggle={props.toggle}>
 <h3>Add levels <MDBIcon className="light-blue-text" icon="dice" /></h3>
 </MDBModalHeader>
 <MDBModalBody className="form-group" >
 <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}>  
<MDBInput  onChange={handleChange}  name="id" label="Id (number)" />
<MDBInput  onChange={handleChange}  name="answer_max_for_level" label="Answer_max_for_level"/>
<MDBInput  onChange={handleChange}  name="answer_min_for_level" label="Answer_min_for_level" />
<MDBInput  onChange={handleChange}  name="groups_on" label="Groups_on"/>
<MDBInput  onChange={handleChange}  name="hard_for_level" label="Hard_for_level" />
<MDBInput  onChange={handleChange}  name="max_points_for_level" label="Max_points_for_level"/>
<MDBInput  onChange={handleChange}  name="max_ranks" label="Max_ranks" />
<MDBInput  onChange={handleChange}  name="mult_answers" label="Mult_answers (number)" />
<MDBInput  onChange={handleChange}  name="mult_rank" label="Mult_rank (number)" />
<MDBInput  onChange={handleChange}  name="mult_relation" label="Mult_relation (number)"/>
</div>
 </MDBModalBody>
 <Alert error={error} getErr={getErr} />
 <MDBModalFooter>
   <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
 </MDBModalFooter>
 </MDBModal>
)
}
