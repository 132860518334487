import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {changeData} from "../../../API.js";
import Alert from "../Modals/Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function EditArticles(props){

  const [error, getErr] = useState();
  const [id, getId] = useState();
  const [data ,setData]= useState({
  language_prop_id: "0",
  slug: "",
  subject: "",
  title: "",
  url: ""
});
  

  const table = 'articles';
  const tableContext = useContext(AppContext);

  const handleChange = (name,value) => {
    setData({
      ...data,
      [name]: value
    });
  };

  const refreshTable = () =>{
    changeData(table,data,getErr, id);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Edit article <MDBIcon className="light-blue-text" far icon="newspaper" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}> 
        <MDBInput onChange={e => getId(Number(e.target.value))} type="number" name="id" label="Id"/>
        <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} name="title"  label="Title" className="mt-2 mb-2" />
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} type="number" name="language_prop_id"  label="Language_prop_id" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="slug"  label="Slug" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} name="subject"  label="Subject" className="mt-2 mb-2" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="url"  label="Url" className="mt-2 mb-2" />
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}