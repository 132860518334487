import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {changeData} from "../../../API.js";
import Alert from "../Modals/Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function EditGame(props){

  const [error, getErr] = useState();
  const [id, getId] = useState();
  const [data ,setData]= useState({
  answer_max_for_level: "",
  answer_min_for_level: "",
  groups_on: "",
  hard_for_level: "",
  max_points_for_level: "",
  max_ranks: "",
  mult_answers: "",
  mult_rank: "",
  mult_relation: ""
});
  

  const table = 'levels';
  const tableContext = useContext(AppContext);
  
  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const refreshTable = () =>{
    changeData(table, data, getErr, id);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Edit levels <MDBIcon className="light-blue-text" icon="dice" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}> 
        <MDBInput onChange={e => getId(e.target.value)}  name="id" label="Id"/>
        <MDBInput  onChange={handleChange}  name="answer_max_for_level" label="Answer_max_for_level"/>
        <MDBInput  onChange={handleChange}  name="answer_min_for_level" label="Answer_min_for_level" />
        <MDBInput  onChange={handleChange}  name="groups_on" label="Groups_on"/>
        <MDBInput  onChange={handleChange}  name="hard_for_level" label="Hard_for_level" />
        <MDBInput  onChange={handleChange}  name="max_points_for_level" label="Max_points_for_level"/>
        <MDBInput  onChange={handleChange}  name="max_ranks" label="Max_ranks" />
        <MDBInput  onChange={handleChange}  name="mult_answers" label="Mult_answers (number)" />
        <MDBInput  onChange={handleChange}  name="mult_rank" label="Mult_rank (number)" />
       <MDBInput  onChange={handleChange}  name="mult_relation" label="Mult_relation (number)"/>
       </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}