import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "../../Navbar.js";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBIcon } from 'mdbreact';
import {currency} from "./Currency.js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_NWe99jF7uW5yuv22MYSQ3wRw00R7GZAANu");

const Message = ({ message }) => (
  <section>
    <p className="mt-2">{message}</p>
  </section>
)
export default function Stripe() {
  const [message, setMessage] = useState();
  const [amount, getAmount] = useState("00.00");
  const [name , getName] = useState("");
  const [donationMessage, getDonationMessage] = useState("");
  const [selectedCurrency, getCurrency]= useState("AED");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setMessage('"Thank you for your donation! We really appreciate it - your support helps us continue to develop LingYourLanguage to its full potential." -Raphael and Ulvi');
    }
    if (query.get("cancelled")) {
      setMessage(
        "You've cancelled your Donation"
      );
    }
  }, []);

  var api = process.env.REACT_APP_API_URL;

 const amountOfMoney = Number(amount);

  var data = {
    full_name:name,
    amount: parseFloat(amountOfMoney.toFixed(2)),
    message: donationMessage,
    string: selectedCurrency
  }

  const handleClick = async (event) => {
    event.preventDefault();
    const stripe = await stripePromise;
    const response = await fetch(`${api}checkout`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data),
    });
    const session = await response.json();
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  return message ? (
    <>
    <Navbar />
    <div className="d-flex justify-content-center mt-5">
    <MDBCol className="mb-5 mt-5" style={{ maxWidth: "26rem" }}>
      <MDBCard style={{background : "#33b5e5"}}>
      <MDBCardBody className="d-flex justify-content-center flex-column ml-4 mr-4 text-white">
    <Message message={message} />
    </MDBCardBody>
    </MDBCard>
    </MDBCol>
    </div>
    </>
  ) : (
    <>
    <Navbar />
    <div className="d-flex justify-content-center">
    <MDBCol className="mb-5 mt-5" style={{ maxWidth: "27rem" }}>
      <MDBCard>
      <MDBCardBody className="d-flex justify-content-center flex-column ml-2 mr-2">
        <form className="product">
          <div className="description d-flex justify-content-center flex-column text-center">
            <h3 style={{marginTop:"-30px"}} className="grey-text font-weight-bold">Donate <MDBIcon icon="hand-holding-usd" /></h3>
            <div className="form-group">
            <MDBInput label="Full name" value={name} onChange={e => getName(e.target.value)} required />
            <div className="d-flex justify-content-row">
            <MDBInput id="amount" label="Amount" type="number" value={amount} onChange={e => getAmount(e.target.value)} required />
            <select onChange={e => getCurrency(e.target.value)}  style={{width: 90, marginTop:"25px"}} className="browser-default custom-select">
          {currency.map((item, i) => <option key={i} value={item}>{item}</option>)}
        </select>
        </div>
            <MDBInput type="textarea" label="Message" value={donationMessage} onChange={e=> getDonationMessage(e.target.value)} rows="5" />
    </div>
          </div>
          <MDBBtn color="primary"  id="checkout-button" role="link" onClick={e => handleClick(e)}>Donate</MDBBtn>
        </form >
        <a style={{marginTop:"-30px"}} target="_blank" rel="noopener noreferrer"  href="https://www.paypal.com/paypalme/lingyourlang">
        <p style={{cursor:"pointer"}}><sub className="blue-text">Use PayPal</sub></p>
        </a>
        </MDBCardBody>
        </MDBCard>
    </MDBCol>
    </div>
      </>
  );
}