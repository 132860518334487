import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {changeData} from "../../../API.js";
import Alert from "../Modals/Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function EditAccount(props){

  const [error, getErr] = useState();
  const [id, getId] = useState();
  const [data ,setData]= useState({
  country: "",
  email:"",
  native_language:"",
  role:"",
  username:"",
  experience: 0
});


  const table = 'accounts';
  const tableContext = useContext(AppContext);
  
  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const refreshTable = () =>{
    changeData(table,data,getErr, id);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Edit Account <MDBIcon className="light-blue-text" icon="user" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}> 
        <MDBInput onChange={e => getId(e.target.value)} type="number"  name="id" label="id" className="mt-2 mb-2" />
        <MDBInput onChange={handleChange}  name="country" type="text" label="Country" className="mt-2 mb-2" />
      <MDBInput onChange={handleChange}  name="email"  type="text" label="Email" />
      <MDBInput onChange={handleChange}  name="native_language" type="text" label="Native lang" className="mt-2 mb-2" />
      <MDBInput onChange={handleChange}  name="role"  type="text" label="Role" />
      <MDBInput onChange={handleChange}  name="username"  type="text" label="Username" />
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}