import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {changeData} from "../../../API.js";
import Alert from "../Modals/Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function EditHighscores(props){

  const [error, getErr] = useState();
  const [id, getId] = useState();
  const [data ,setData]= useState({
  account_id: "0",
  difficulty: "",
  name: "",
  score: 0
});


  const table = 'highscores';
  const tableContext = useContext(AppContext);
  
  const handleChange = (name,value) => {
    setData({
      ...data,
      [name]: value
    });
  };

  const refreshTable = () =>{
    changeData(table,data,getErr, id);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Edit Highscore <MDBIcon className="light-blue-text" icon="trophy" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}> 
        <MDBInput onChange={e => getId(e.target.value)} type="number"  name="id" label="id" className="mt-2 mb-2" />
        <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}  name="account_id" type="text" label="account id" className="mt-2 mb-2" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}   name="difficulty"  type="text" label="difficulty" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)}   name="name" type="text" label="name" />
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} name="score" type="number" label="score" className="mt-2 mb-2" />
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}