import React, {useState, useEffect}from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter } from 'mdbreact';
import {Link} from 'react-router-dom';
import "./login.css";
import img from "./profile.png";

export default function ModalSignUp(props){

const [user, getUser] = useState()


  return (
    <MDBContainer>
      <MDBModal isOpen={props.open}>
        <MDBModalBody>
          <h5>You have successfully created your account</h5>
          <img src={img} alt="" width="200" height="200" />
        </MDBModalBody>
        <MDBModalFooter>
         <Link to="/signin"><MDBBtn color="primary">Log In</MDBBtn></Link>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
    );
};