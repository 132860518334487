import React, {useState, useEffect} from "react";
import Navbar from "./Navbar.js";
import Audio from "./Audio.js";
import Language from "./Language.js";
import Game from "./Game.js";
import LanguageProps from "./LanguageProps.js";
import Articles from "./Articles.js";
import Highscores from "./Highscores.js";
import Accounts from "./Accounts.js";
import "./admin.css";
import {AppContext} from "../../AppContext.js";
import { Redirect } from "react-router-dom";
import {getCookie} from "../../API.js";
import { useHistory } from "react-router-dom";


export default function AdminPanel(props){

const [page,getPage]= useState("audio");
const[tableUpdated, getTableUpdate] = useState(false);

let role = getCookie('role');

const history = useHistory();

useEffect(() => {
role = getCookie('role');

 if(role !== "ADMIN"){
     props.getRerender(!props.rerender);
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    history.push('/signin');
     }
 },[ page , tableUpdated])

return(
    <>
    {role === 'ADMIN' ?
    <AppContext.Provider value={{tableUpdated, getTableUpdate}}>
     <div id="admin">
<Navbar getPage={getPage} page={page} />
{page === 'audio' ? <Audio /> : null ||
page === 'language' ? <Language /> : null ||
page === 'game' ? <Game  /> : null ||
page === 'langprops' ? <LanguageProps /> : null ||
page === 'articles' ? <Articles /> : null ||
page === 'highscores' ? <Highscores /> : null ||
page === 'accounts' ? <Accounts /> : null 
}
    </div>
    </AppContext.Provider>
:  <Redirect to='/account'  />}
    </>
)

}