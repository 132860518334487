import React, {useEffect} from "react";
import Navbar from "../../Navbar.js";
import Footer from "./Footer.js";
import {MDBAnimation} from "mdbreact";
import DonateModal from "../DonateModal.js";
import img1 from "./university-1.png";
import img2 from "./mlm_logo.jpg";

export default function About(){

	useEffect(()=>{
		window.scrollTo(0,0);
	},[])

    return(
        <>
<Navbar/>
<MDBAnimation type="fadeIn">
        <div className="mt-5">
        <div id="about"  className="col-md-8 offset-md-2">
            <h1 className="mb-4">About Us</h1>
					<div className="row text-left">
					<p style={{lineHeight: 2 + 'em',  margin: 0, width: '100%'}} className="text-left">
                    Inspired by “The Great Language Game”, LingYourLanguage is a collaborative effort to bring the world’s languages to a wider audience in an entertaining, engaging way. The project began at a hostel in the center of Prague, Czech Republic, where visitors began contributing their languages to the LingYourLanguage collection. It soon spread to family, friends, acquaintances, friends of friends, and so on, and links together people and cultures. We hope to create a collection of all the different languages, varieties, dialects, accents, and linguistic peculiarities that are such an important part of cultures around the world.</p><br />
                   <p style={{lineHeight: 2 + 'em', margin: "15px 0"}} className="text-left">
                  If you enjoy LingYourLanguage, please consider making a donation. The game is not for profit and has no advertising, with all donations going towards developing new features and expanding the educational potential of the game. </p>
        <div style={{ display: "flex", flexDirection: 'column' }}>
        <strong className="mb-1">Thanks to the generous donations of our users, the game now has:</strong>
       <div style={{ display: "flex", flexDirection: 'column', paddingLeft: "30px" }}>
      <i>-  Over 2,500 samples in nearly 100 languages and 200 dialects from around the world</i>
     <i>-  Information on every language available both in-game and on a dedicated Learn page</i>
     <i>-  User profiles: track your high scores and compare them with other players, and level up your account from mere “Language enthusiast” to “Omniglot” – master of all languages!</i>	
     <i>-  Significantly improved game logic, with multiple difficulty levels to allow anyone to play the game and learn the sounds of the different languages</i>
     <i>-  Two different multiplayer modes, each for up to four players - compete with friends and family to see who’s got the best ear for languages!</i>
								</div>
							</div>

							<br />

							<p style={{lineHeight: 2 + 'em'}} className="text-left">
								We’re still hard at work on the continual addition of languages and dialects as well as more detailed information on them, more in-depth articles, collaboration with professors and organizations from around the
								world to provide authentic, high-quality audio, and more.</p>

							<p style={{lineHeight: 2 + 'em'}} className="text-left">
								We hope you will help LingYourLanguage reach its full potential, and we hope you enjoy the game!
								<br/>
							<i><strong>-Raphael and Ulvi</strong></i>
								</p>
							<br/>

							<hr
								style={{
									border: 'none',
									height: 1+'px',
									color: '#333',
									backgroundColor: '#333',
									opacity: 0.4
								}}
							/>

							<p style={{lineHeight: 2 + 'em'}} className="text-left">
							<strong>We would like to thank all of those who have helped us
								 with LingYourLanguage in many ways (names in alphabetical order by surname).</strong> 
							</p>

							<div style={{ display: "flex", flexDirection: 'column' }}>
								<i><strong>Prof.</strong> Jordi Aguadé (Universidad de Cadiz)</i>
								<i><strong>Prof.</strong> Dr. Jan Beth-Sawoce and the Surayt-Aramaic Online Project</i>
								<i><strong>Prof.</strong> Maciej Klimiuk (Heidelberg University)</i>
								<i><strong>Prof.</strong> Catherine Miller (Institut de recherches et d’études sur les mondes arabes et musulmans) </i>
								<i><strong>Prof.</strong> Yaron Matras (University of Manchester)</i>
								<i><strong>Prof.</strong> Stephen Procházka (Universität Wien)</i>
								<i><strong>Prof.</strong> Dr. Shabo Talay (Heidelberg University)</i>
								<br/>
								<strong>
								<i>Yaron Matras's Archive of Endangered and Smaller Languages</i>
								<br/>
								<i>FAR LOMBARD (associazion per la lengua lombarda)</i>
								<br/>
								<i>Nipivut (Montrealmiut Community Radio)</i>
								<br/>
								<i className="mb-3">Multilingual Manchester</i>
								</strong>
								<br/>
								<div style={{display: "flex"}} className='about_image'>
									<img style={{height: "75px", marginRight: "80px"}} src={img1} alt="logo"/>
									<img style={{height: "75px"}} src={img2} alt="logo"/>
								</div>
							</div>

							<hr
								style={{
									border: 'none',
									height: 1+'px',
									color: '#333',
									backgroundColor: '#333',
									opacity: 0.4
								}}
							/>
					</div>
				</div>
				<hr className="mt-3" />
				<div className="w-100 d-flex justify-content-center">
				<div className="mt-2 mb-2 w-50">
				<p>If you enjoy LingYourLanguage, please consider making a donation. 
        The game is not for profit and has no advertising,
         and so we hope you will help LingYourLanguage reach its full potential.
         <br/> 
         <strong>Thank you!</strong>
         </p>
				<DonateModal />
				</div>
				</div>
<Footer/>
        </div>
		</MDBAnimation>
        </>

    )
}