import React,{useContext, useEffect} from "react";
import Difficulty from "./Difficulty.js";
import Play from "./Play.js";
import GameOver from "./GameOver.js";
import { GameContext } from "./Context.js";
import Score from "./Score.js";

export default function SinglePlayer(){

    const game = useContext(GameContext);

    useEffect(()=>{
        
    },[game])
    return(
        <>
{ !game.gameOver  ?
<>
{game.difChoosen ? null : <Difficulty getDifficulty={game.getDifficulty} difChoosen={game.difChoosen} />}

{game.difChoosen && game.options && game.nextLevel ? <>  <Score  /> <Play />  </> : null}
 </>
: <GameOver />}

        </>
    )
}