import React, {useState, useContext, useEffect} from "react";
import { MDBInput, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import {changeData, getTable} from "../../../API.js";
import Alert from "../Modals/Alert.js";
import { AppContext } from "../../../AppContext.js";

export default function EditGame(props){

  const [error, getErr] = useState();
  const [id, getId] = useState();
  const [tableData,getData]=useState();
  const [loaded, isLoaded] = useState(false);
  const [data ,setData]= useState({
    name: "",
    family: "",
    region: "",
    branch: "",
    subbranch: "",
    grp: "",
    subgroup: "",
    subsubgroup: "",
    variety: "",
    rank: 0, 
    language_prop_id: 0
  });
  

  const table = 'languages';
  const tableContext = useContext(AppContext);
  

  const handleChange = (name,value) => {
    setData({
      ...data,
      [name]: value
    });
  };

  const refreshTable = () =>{
    changeData(table, data, getErr, id);
    tableContext.getTableUpdate(true);
    setTimeout(function(){ tableContext.getTableUpdate(false)}, 500);
  }

  getTable('languages',getData,isLoaded);



// const getLang = (ids) =>{
//  const match = tableData.filter(item => item.id === ids);
//   setData({
//     name:`${match.name}`,
//     family:`${match.family}`,
//     region:`${match.region}`,
//     branch:`${match.branch}`,
//     subbranch:`${match.subbranch}`,
//     grp:`${match.grp}`,
//     subgroup:`${match.subgroup}`,
//     subsubgroup:`${match.subsubgroup}`,
//     variety:`${match.variety}`,
//     rank:`${match.rank}`,
//     language_prop_id:`${match.language_prop_id}`
//   })

// }

  useEffect(()=>{
    if(!props.modal){
      getErr(undefined)
    }else{
      return
    }
  },[props.modal])

return(
  <MDBModal isOpen={props.modal} toggle={props.toggle}>
           <MDBModalHeader toggle={props.toggle}>
        <h3>Edit language <MDBIcon far  className="light-blue-text" icon="language" /></h3>
        </MDBModalHeader>
        <MDBModalBody className="form-group">
        <div className="form-group scrollbar scrollbar-primary" 
        style={{textTransform: "capitalize",height:"50vh", overflowY:"scroll", overflowX:"hidden"}}>  
      <MDBInput onChange={(e) => getId(Number(e.target.value))} type="number" name="id" label="Id"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="name" label="Name" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="family" label="Family"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="region" label="Region" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="branch" label="Branch" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="subbranch" label="Subbranch"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="grp" label="Grp" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="subgroup" label="Subgroup"/>
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="subsubgroup" label="Subsubgroup" />
      <MDBInput onChange={e => handleChange(e.target.name, e.target.value)} type="text"  name="variety" label="Variety"/>
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} type="number"  name="rank" label="Rank (number)" />
      <MDBInput onChange={e => handleChange(e.target.name, Number(e.target.value))} type="number"  name="language_prop_id" label="Language_prop_id (number)"/>
      </div>
        </MDBModalBody>
        <Alert error={error} getErr={getErr} />
        <MDBModalFooter>
          <MDBBtn color="info" onClick={e => refreshTable()}>Submit</MDBBtn>
        </MDBModalFooter>
    </MDBModal>
)
}