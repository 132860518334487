import React, {useState, useEffect, useContext} from "react";
import { generalSearch} from "./Search.js";
import {getArticles} from "../../API.js";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';
import { AppContext } from "../../AppContext.js";

export default function Articles(){

    const[datas, getDatas] = useState();
    const [loaded, isLoaded] = useState(false);

    const tableContext = useContext(AppContext);

  useEffect(()=>{
  async function articles() {
    const article = await getArticles();
    getDatas(article);
  }
  articles();
  setTimeout(() => isLoaded(true), 300)
},[tableContext.tableUpdated])



      return(
          <>
{loaded ? <> <h5 className="mt-5">Articles <MDBIcon className="light-blue-text" far icon="newspaper" /></h5>  
<MDBTable responsive hover small bordered id="myTable" style={{width: "99%"}} striped>
        <MDBTableHead>
        <tr>
            <th><input className="form-control" id="myInputA" onChange={e => generalSearch(0,e.target.id)} placeholder="search id" /></th>
            <th><input className="form-control" id="myInputB" onChange={e => generalSearch(1,e.target.id)}  placeholder="search subject" /></th>
            <th><input className="form-control" id="myInputC" onChange={e => generalSearch(2,e.target.id)}  placeholder="search title" /></th>
            <th><input className="form-control" id="myInputD" onChange={e => generalSearch(3, e.target.id)}  placeholder="search url" /></th>
            <th><input className="form-control" id="myInputE" onChange={e => generalSearch(4,e.target.id)}  placeholder="search uniq_link" /></th>
          </tr>
          <tr style={{background:"#33b5e5", color:"white"}}>
            <th>Id</th>
            <th>Subject</th>
            <th>Title</th>
            <th>Url</th>
            <th>Uniq_link</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
        {loaded ? 
        datas.map(data =>
        <tr key={data.id}>
        <td>{data.id}</td>
        <td>{data.subject}</td>
        <td>{data.title}</td>
        <td>{data.url}</td>
        <td>{data.thumbnail_url}</td>
      </tr>
        ) 
        : null}
        </MDBTableBody>
      </MDBTable> </> :
      null
}
          </>
          
      )
  }