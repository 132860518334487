import React, { useState, useContext } from "react";
import {MDBNavbar,  MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBIcon} from "mdbreact";
import AudioModal from "./Modals/AudioModal.js";
import LanguageModal from "./Modals/LanguageModal.js";
import GameModal from "./Modals/GameModal.js";
import LanguagePropsModal from "./Modals/LanguagePropsModal.js";
import ArticlesModal from "./Modals/ArticlesModal.js";
import HighscoresModal from "./Modals/HighscoresModal.js";
import DeleteModal from "./Modals/DeleteModal.js";
import EditArticles from "./EditModals/EditArticles.js";
import EditGame from "./EditModals/EditGame.js";
import EditLanguage from "./EditModals/EditLanguage.js";
import EditLangProps from "./EditModals/EditLangProps.js";
import EditHighscores from "./EditModals/EditHighscores.js";
import EditAccount from "./EditModals/EditAccount.js";
import { AppContext } from "../../AppContext.js";

function Navbar(props) {

  const[open,isOpen] = useState(false);
  const[modal,getModal] = useState(false);
  const[trashModal, getTrashModal] = useState(false);
  const[editModal, getEditModal] = useState(false);

  const tableContext = useContext(AppContext);

const toggleCollapse = () => {
  isOpen(!open)
}

 const toggle = () => {
    getModal(!modal)
  }

const trashToggle = () => {
    getTrashModal(!trashModal);
  }

  const editToggle = () => {
    getEditModal(!editModal);
  }

  return (
    <>
      <MDBNavbar color="info-color" dark expand="md">
        <MDBNavbarToggler onClick={e => toggleCollapse()} />
        <MDBCollapse id="navbarCollapse3" isOpen={open} navbar>
          <MDBNavbarNav left>
          <MDBNavItem>
          <MDBNavLink  onClick={ e => props.getPage("audio")} to="#">Audio</MDBNavLink>
            </MDBNavItem>
          <MDBNavItem>
          <MDBNavLink onClick={e => props.getPage("language")}  to="#">Language</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink onClick={e => props.getPage("game")}  to="#">Game</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink onClick={e => props.getPage("langprops")} to="#!">LanguageProps</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink onClick={e => props.getPage("articles")} to="#!">Articles</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink onClick={e => props.getPage("highscores")} to="#!">Highscores</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink onClick={e => props.getPage("accounts")} to="#!">Accounts</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right className="d-flex flex-row justify-content-center">
            {props.page === 'audio' ? null : <>
          <MDBNavItem>
            <MDBIcon className="ml-2 mt-2 mr-3 white-text" icon="edit" onClick={e => editToggle()}  style={{fontSize: 25, cursor:"pointer"}} />
            </MDBNavItem>
            </> }
          <MDBNavItem>
            <MDBIcon className="ml-2 mt-2 mr-3 white-text" icon="trash-alt" onClick={e => trashToggle()}  style={{fontSize: 25, cursor:"pointer"}} />
            </MDBNavItem>
            {props.page === 'accounts' ? null : <>
            <MDBNavItem>
            <MDBIcon className="mr-2 mt-2 white-text" icon="plus" onClick={e => toggle()} style={{fontSize: 25, cursor:"pointer"}} />
            </MDBNavItem>
            </>}
            <MDBNavItem>
            <MDBNavLink to="/account">
            <MDBIcon className="ml-1  mr-2 white-text" icon="user"  style={{fontSize: 25, cursor:"pointer"}} />
            </MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>

      {props.page === 'articles' ? <EditArticles toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
      props.page === 'game' ?
      <EditGame toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
      props.page === 'language' ?
      <EditLanguage toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
      props.page === 'langprops' ?
      <EditLangProps toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
      props.page === 'highscores' ?
      <EditHighscores toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
      props.page === 'accounts' ?
      <EditAccount toggle={editToggle} modal={editModal} getTableUpdate={tableContext.getTableUpdate} /> : null}

     {props.page === 'audio' ? <AudioModal  toggle={toggle} getTableUpdate={tableContext.getTableUpdate}
     modal={modal} /> : null || props.page === 'language' ?
     <LanguageModal getTableUpdate={tableContext.getTableUpdate} toggle={toggle} modal={modal}  /> : null || 
     props.page === 'game' ?
     <GameModal toggle={toggle} modal={modal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
     props.page === 'langprops' ?
     <LanguagePropsModal toggle={toggle} modal={modal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
     props.page === 'articles' ?
     <ArticlesModal toggle={toggle} modal={modal} getTableUpdate={tableContext.getTableUpdate} /> : null ||
     props.page === 'highscores' ?
     <HighscoresModal toggle={toggle} modal={modal} getTableUpdate={tableContext.getTableUpdate} /> : null}

     <DeleteModal toggle={trashToggle} page={props.page} modal={trashModal} getTableUpdate={tableContext.getTableUpdate} />
      </>
    );
  }

export default Navbar;