import React, {useState, useContext, useEffect} from "react";
import Play from "./Play.js";
import PlayerNames from "./PlayerNames.js";
import Difficulty from "./Difficulty.js";
import Score from "./Score.js";
import { GameContext } from "./Context.js";
import GameOver from "./GameOverMulti.js";

export default function MultiPlayer(){

const game = useContext(GameContext);

const [hasPlayers, getPlayers] = useState(false);

useEffect(()=>{

},[game])

return(
    <>
{ !game.gameOver ?
    <>
{game.difChoosen ? null : <Difficulty getDifficulty={game.getDifficulty} difChoosen={game.difChoosen} />}

{game.difChoosen && !hasPlayers ? <PlayerNames getPlayers={getPlayers}  hasPlayers={hasPlayers} /> : null}

{hasPlayers && game.nextLevel ?<>  <Score /> <Play />  </> : null}
 </>
:  <GameOver /> }
        </>
)
}